import Router from '../router';
import ShoppingCart from '../shoppingcart';
import Retoure from '../retoure';
import View from './view';
import SubView from './sub_view';
import HomeView from './home_sub_view';
import DocumentsView from './documents_sub_view';
import CleaningPlansView from './cleaningplans_sub_view';
import LabReportsView from './labreports_sub_view';
import ProductsView from './products_sub_view';
import ContactView from './contact_sub_view';
import SettingsView from './settings_sub_view';
import FileExchangeView from './file_exchange_sub_view';
import PageSpinner, {
    Dimmer
} from '../spinner.js';
import Session from '../session.js';
import * as Service from '../service.js';

import EmptyPreviewItem from '../../templates/empty_preview_item.hbs';
import DocumentPreviewItem from '../../templates/document_preview_item.hbs';
import DocumentItem from '../../templates/document_item.hbs';
import MenuItemClients from '../../templates/menu_item_clients.hbs';
import { Holidays } from "../Holidays";


class MainView extends View {
    protected $parent;
    protected currentSubView: View;
    protected currentSubViewName: string;

    init(subView: string) {
        PageSpinner.show();

        this.$parent = $('.main-content');
        const self = this;
        $('.main-content').load('templates/main.html', function(data) {
            $(this).localize();
            self._bindEvents();
            self.initResizeLogo();
            PageSpinner.hide();

            $('.ui.dropdown').dropdown();
            $('.ui.checkbox').checkbox();

            Service.getClientData()
                .then((data: { client: any, user: any }) => {
                    let client;
                    let user;
                    if (data.hasOwnProperty('user')) {
                        client = data.client;
                        user = data.user;
                        Session.setUserData('client', data.client);
                        Session.setUserData('user-info', data.user);
                    } else {
                        // Kompatibilität mit altem RestService
                        client = data;
                        user = null;
                        Session.setUserData('client', data);
                        Session.setUserData('user-info', null);
                    }

                    // Warenkorb initialisieren
                    ShoppingCart.init(client, user);
                    window.Cart = ShoppingCart;

                    // Retoure initialisieren
                    Retoure.init(client, user);
                    window.Retoure = Retoure;

                    // Reinigungspläne & Dateiaustausch initialisieren
                    self._initCleaningPlans(client, user);
                    self._initUserFilesSharing(client, user);
                    self._initAllowedClients(client, user);

                    // enable workshops link to customers with Language=DE
                    $(this).find('.menu .workshops.item').toggleClass('hidden', (Session.getLanguage() || '').toUpperCase() !== 'DE');
                    return Service.getAssignedSupporter();
                })
                .then((data) => {
                    self._setupSupporter(data);
                }, () => {
                    self._setupSupporter(null);
                })
                .then(() => {
                    self.show(subView);
                });
        });
    }

    show(subViewName: string) {
        const allowedViews = [
            'contact',
            'documents',
            'purchase',
            'lab-reports',
            'settings',
            'retoure'
        ];

        if (this._hasCleaningPlansEnabled()) {
            allowedViews.push('cleaning-plans');
        }

        if (this._hasUserFilesSharingEnabled()) {
            allowedViews.push('file-exchange');
        }

        if (!!subViewName && allowedViews.indexOf(subViewName) < 0) {
            Router.pushState('main');
            return;
        }

        let newViewClass: any;
        switch (subViewName) {
            case 'contact':
                newViewClass = ContactView;
                break;
            case 'documents':
                newViewClass = DocumentsView;
                break;
            case 'purchase':
                newViewClass = ProductsView;
                break;
            case 'cleaning-plans':
                newViewClass = CleaningPlansView;
                break;
            case 'lab-reports':
                newViewClass = LabReportsView;
                break;
            case 'settings':
                newViewClass = SettingsView;
                break;
            case 'retoure':
                Retoure.show();
                // Module Einstieg Event erfassen
                Service.logStatisticsEvent(subViewName, 'open');
                Router.restoreFragment();
                return;
            case 'file-exchange':
                newViewClass = FileExchangeView;
                break;
            default:
                subViewName = 'home';
                newViewClass = HomeView;
                break;
        }

        // Module Einstieg Event erfassen
        Service.logStatisticsEvent(subViewName, 'open');

        // check new instance of View
        /*
        if(!(newViewClass instanceof SubView)) {
            throw new TypeError('SubView muss von View ableiten!');
        }
        */

        if (!this.currentSubView || this.currentSubView.constructor !== newViewClass) {
            const wait = $.Deferred();
            if (this.currentSubView) {
                this.currentSubView.hide()
                    .then(() => {
                        this.currentSubView.dispose();
                        wait.resolve();
                    });
            } else {
                wait.resolve();
            }
            wait.then(() => {
                this.currentSubView = new newViewClass(this.$parent.find(`.row.${subViewName}`));
                this.currentSubView.init();
                this.currentSubView.show();
            });
        }

        this.selectActiveTab(subViewName);

        this.currentSubViewName = subViewName;
    }

    getCurrentViewName(): string {
        return this.currentSubViewName;
    }

    selectActiveTab(tab: string) {
        this.$parent.find('.head.row .menu .item.active').toggleClass('active', false);

        const item = this.$parent.find('.head.row .menu .item.' + tab);
        if (item.length > 0) {
            item.addClass('active');
        }
    }

    _bindEvents() {
        this.$parent.find('.menu').on('click', '.item', (evt: Event) => {
            const item = $(evt.currentTarget);
            if (item.hasClass('logout')) {
                this.logout();
                return;
            }

            if ((item.hasClass('client') || item.hasClass('client-selection')) && !isNaN(item.data('id'))) {
                Session.setPreferredClient(item.data('id'));
                window.location.reload();
                return false;
            }
        });

        this.$parent.find('a.certificate.item').on('click', (evt: Event) => {
            Service.logStatisticsEvent('certificate', 'open-link');
        });
    }

    _unbindEvents() {
        this.$parent.find('.menu').off('click', '.item');
    }

    _setupSupporter(data) {
        if (!data) {
            return;
        }
        let image = null;
        if (data['image-id']) {
            image = Service.getSupporterThumbUrl(data['image-id']);
        }
        this.$parent.find('.contact .thumb').attr('src', image);
        this.$parent.find('.contact .name').text(`${data['first-name']} ${data['last-name']}`);
        this.$parent.find('.contact .position').text(data.position);
        this.$parent.find('.contact .tel-nr').text(data['tel-nr']);
        this.$parent.find('.contact .contact-email').text(data['contact-email']).attr('href', `mailto:${data['contact-email']}`);
    }

    _initCleaningPlans(client, user) {
        let showCleaningPlans = this._hasCleaningPlansEnabled(user);

        if (!showCleaningPlans) {
            // keine Reinigungspläne anzeigen
            $('.card.cleaning-plans').addClass('hidden');
            $('.menu .cleaning-plans.item').addClass('hidden');
            return;
        }
    }

    _initUserFilesSharing(client, user) {
        let showUserFilesSharing = this._hasUserFilesSharingEnabled(user);

        if (!showUserFilesSharing) {
            // kein Dateiaustausch anzeigen
            $('.card.file-exchange').addClass('hidden');
            $('.menu .file-exchange.item').addClass('hidden');
            return;
        }
    }

    _initAllowedClients(client, user) {
        return Service.getAllowedClients()
            .then((availableClients: any[]) => {

                if (!availableClients || !availableClients.length) {
                    // hide switch menu if no other clients are active
                    return;
                }

                // add current client
                if (!availableClients.some(val => val.id == client.id)) {
                    availableClients.push(client);

                    availableClients.sort(function(a, b) {
                        if (a.company == b.company) {
                            return 0;
                        }

                        return a.company > b.company ? -1 : 1;
                    })
                }

                if (availableClients && availableClients.length == 1) {
                    // hide switch menu if no other clients are active
                    return;
                }

                const $newMenuItem = $(MenuItemClients(availableClients));
                $newMenuItem.localize();
                $newMenuItem.insertBefore(this.$parent.find('.menu .item.logout'));

                // mark current client as selected
                const $currentSelection = $newMenuItem.find(`[data-id="${client.id}"]`);
                $currentSelection.find('.icon').removeClass('outline');
                $currentSelection.find('span').wrapInner('<b></b>');
            });
    }

    _hasCleaningPlansEnabled(user?): boolean {
        user = user || Session.getUserData('user-info');

        if (!user || user["is-cleaningplans-enabled"] === null) {
            const client = Session.getUserData('client');
            if (client && client.settings) {
                // keine Reinigungspläne anzeigen
                return !!client.settings["is-cleaningplans-enabled"];
            }
        } else {
            return user["is-cleaningplans-enabled"];
        }
    }

    _hasUserFilesSharingEnabled(user?) {
        user = user || Session.getUserData('user-info');
        const client = Session.getUserData('client');

        if (!client || !client.settings ||
            !client.settings["is-userfiles-sharing-enabled"]) {
            // Dateiaustausch am Mandanten ist deaktiviert
            return false;
        }

        if (user && user["is-userfiles-sharing-enabled"] === false) {
            // Dateiaustausch am benutzer ist deaktiviert
            return false;
        }

        return true;
    }

    logout() {
        Service.logStatisticsEvent('main', 'logout');
        // reset preferred client
        Session.setPreferredClient(null);
        // clear cart on logout
        ShoppingCart.clear();
        // clear retoure on logout
        Retoure.clear();
        // logout user
        Service.logout();
        Session.setLoggedIn(false);
        Session.setAuthString(null);
        Router.pushState('login');
    }

    dispose() {
        this._unbindEvents();
    }

    initResizeLogo() {
        $(window).on('resize', (evt: Event) => {
            let logo = $('.head.row > .logo.column');
            let menu = $('.head.row > .menu-bar');
            let width = $(window).width();
            logo.removeClass('two three four wide');
            menu.removeClass('twelve thirteen fourteen wide');

            if (width <= 1100) {
                logo.addClass('two wide');
                menu.addClass('fourteen wide');
            } else if (width <= 1200) {
                logo.addClass('three wide');
                menu.addClass('thirteen wide');
            } else {
                logo.addClass('four wide');
                menu.addClass('twelve wide');
            }
        });

        $(window).trigger('resize');
    }
}

export default MainView;
