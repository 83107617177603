import i18next from 'i18next';

﻿class Message {

    show(title: string, content: string, onClose?: Function) {
        if ($('body > .msg').length === 0) {
            this._createMessageWindow();
        }

        $('body > .msg.modal .header p').text(title ? title : '').toggleClass('hidden', !title);
        $('body > .msg.modal .content p').text(content ? content : '').toggleClass('hidden', !content);

        $('body > .msg.modal')
            .modal({
                inverted: true,
                closable: false,
                onApprove: onClose || $.noop
            })
            .modal('refresh')
            .modal('show');
    }

    prompt(title: string, content: string, onConfirm: Function, onCancel: Function) {
        const resultDeferred = $.Deferred();

        if ($('body > .prompt').length === 0) {
            this._createPromptWindow();
        }

        $('body > .prompt.modal .header').text(title ? title : '').toggleClass('hidden', !title);
        $('body > .prompt.modal .content').toggleClass('hidden', !content).find('p').html(content ? content : '');

        $('body > .prompt.modal')
            .modal({
                allowMultiple: true,
                closable: false,
                onDeny: () => {
                    if (onCancel) {
                        onCancel();
                    }

                    resultDeferred.reject();
                },
                onApprove: () => {
                    if (onConfirm) {
                        onConfirm();
                    }

                    resultDeferred.resolve();
                }
            })
            .modal('refresh')
            .modal('show');

        return resultDeferred.promise();
    }

    hide() {
        $('body > .message .modal').modal('hide');
    }

    _createMessageWindow() {
        $('body').append([
            '<div class="ui basic tiny msg modal">',
            '<div class="header">',
            '<p>',
            '</p>',
            '</div>',
            '<div class="content">',
            '<p>',
            '</p>',
            '</div>',
            '<div class="actions">',
            '<div class="ui positive halag-red ok button">',
            i18next.t('portal.ok'),
            '</div>',
            '</div>',
            '</div>'
        ].join(''));
    }

    _createPromptWindow() {
        $('body').append([
            '<div class="ui tiny prompt modal">',
            '<div class="header">',
            '</div>',
            '<div class="content">',
            '<p>',
            '</p>',
            '</div>',
            '<div class="actions">',
            '<div class="ui positive ok button">',
            i18next.t('portal.yes'),
            '</div>',
            '<div class="ui negative halag-red cancel button">',
            i18next.t('portal.no'),
            '</div>',
            '</div>',
            '</div>'
        ].join(''));
    }
}

export default (new Message());
