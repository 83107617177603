import View from './view';
import PageSpinner from '../spinner.js';

class FileNotFoundView extends View {
    protected $parent;
    protected currentSubView: View;

    init(subView: string) {
        PageSpinner.show();

        this.$parent = $('.main-content');
        const self = this;
        $('.main-content').load('templates/file_not_found.html', function(data) {
            PageSpinner.hide();
            $(this).localize();

            if (history.length <= 2) {
                self.$parent.find('button.back').addClass('hidden');
            } else {
                self._bindEvents();
            }
            self.initResizeLogo();
        });
    }

    show() {

    }

    _bindEvents() {
        this.$parent.find('button.back').on('click', (evt: Event) => {
            // check if history available otherwise close window
            history.go(-2);
        });
    }

    _unbindEvents() {
        this.$parent.find('button.back').off('click');
    }

    dispose() {
        this._unbindEvents();
    }

    initResizeLogo() {
        $(window).on('resize', (evt: Event) => {
            let logo = $('.head.row > .logo.column');
            let menu = $('.head.row > .menu-bar');
            let width = $(window).width();
            logo.removeClass('two three four wide');
            menu.removeClass('twelve thirteen fourteen wide');

            if (width <= 1100) {
                logo.addClass('two wide');
                menu.addClass('fourteen wide');
            } else if (width <= 1200) {
                logo.addClass('three wide');
                menu.addClass('thirteen wide');
            } else {
                logo.addClass('four wide');
                menu.addClass('twelve wide');
            }
        });

        $(window).trigger('resize');
    }
}

export default FileNotFoundView;
