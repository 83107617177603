{{#each data}}
    <div class="row" data-id="{{id}}" data-article="{{article-nr}}">
        <div class="right aligned three wide article-nr column">
            {{article-nr}}
        </div>
        <div class="seven wide name column">
            {{name}}
        </div>
        <div class="three wide column">
            <div class="ui middle aligned grid actions">
                <div class="right aligned four wide column">
                    <div class="ui small basic circular subtract icon button">
                        <i class="ui minus icon"></i>
                    </div>
                </div>
                <div class="center aligned eight wide column">
                    <div class="ui fluid input">
                        <input class="amount" type="number" value="{{amount}}" />
                    </div>
                </div>
                <div class="left aligned four wide column">
                    <div class="ui small basic circular add icon button">
                        <i class="ui plus icon"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="left aligned one wide unit column">
            {{unit}}
        </div>
        {{#if ../show-remove-icon}}
            <div class="two wide center aligned column">
                <div class="ui basic circular delete icon button" title="Artikel aus Liste entfernen" data-i18n="[title]retoure.remove-from-list">
                    <i class="ui remove icon"></i>
                </div>
            </div>
        {{/if}}
    </div>
{{else}}
    {{#if EmptyText}}
        <span>{{EmptyText}}</span>
    {{else}}
        <span data-i18n="retoure.no-items-selected">Keine Artikel ausgewählt.</span>
    {{/if}}
{{/each}}
