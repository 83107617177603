<div class="ui modal">
    <div class="ui inverted dimmer">
        <div class="ui text loader" data-i18n="upload.progress-upload">Upload...</div>
    </div>

    <i class="close icon"></i>
    <div class="content">
        <div class="ui placeholder fluid segment dropzone">
            <div class="ui icon header">
                <i class="file outline icon"></i>
                <span data-i18n="upload.select-for-upload">Dateien zum Hochladen wählen oder hineinziehen.</span>
            </div>
            <div>
                <label for="file" class="ui icon primary button">
                    <i class="file icon"></i>
                    <span data-i18n="upload.select-file">Datei wählen</span>
                </label>
                <input type="file" id="file" style="display:none">
            </div>
        </div>

        <div class="ui items description hidden">
            <div class="item">
                <i class="huge halag-red-text {{document_icon name}} file outline icon"></i>
                <div class="content">
                    <a class="header filename">Filenname.xyz</a>
                    <div class="meta filesize">
                        <span>1 MB</span>
                    </div>
                    <div class="extra date">
                        01. Dez 2019
                    </div>
                </div>
            </div>
            <div class="ui fluid input">
                <div class="ui top attached label" data-i18n="upload.description">Beschreibung</div>
                <textarea style="width:100%"></textarea>
            </div>
            <br />
            <div class="ui blue fast indeterminate progress hidden">
                <div class="bar">
                    <div class="progress" data-i18n="upload.progress-upload">Upload...</div>
                </div>
            </div>
        </div>

        <div class="ui negative message hidden">
            <div class="header" data-i18n="upload.error-occured">
                Es ist ein Fehler aufgetreten!
            </div>
            <p>!</p>
        </div>
    </div>
    <div class="actions">
        <div class="ui black deny button" data-i18n="upload.cancel">
            Abbrechen
        </div>
        <div class="ui disabled positive right labeled icon button">
            <span data-i18n="upload.save">Speichern</span>
            <i class="checkmark icon"></i>
        </div>
    </div>
</div>
