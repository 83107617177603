import Router from '../router';
import LoginView from './login';
import RecoverView from './recover';
import RegisterView from './register';
import MainView from './main';
import FileNotFoundView from './file_not_found';
import View from './view';
import Session from '../session';
//import PageLoader from '../spinner';
import * as Utils from '../utils';
import i18next from 'i18next';

import { setNewLanguage } from '../utils';

/*
 * ViewManager dient zur Verwaltung und zur Umschaltung zwischen
 * unterschiedlichen Ansichten
 */
class ViewManager {
    protected currentView: View;

    constructor() {
        // Aktuell aktive Ansicht
        this.currentView = null;
        this.initStartPage();
        this.initRoutes();
        this.initLanguage();
        this.initFooterScroll();
    }

    initStartPage() {
        // Bei Direkteinstieg auf eine Ansicht Anmeldung prüfen
        if (Router.getState() == '404') {
            // just let it access
        } else if (!Session.isLoggedIn() && Router.getState() !== 'recover' && Router.getState() !== 'register') {
            Router.pushState('login');
        } else if ((!Router.getState() || Router.getState() === 'login') && !Utils.getParameterFromUrl('t')) {
            Router.pushState('main');
        }
    }

    initRoutes() {
        // Login Route hinzufügen
        this.addViewRoute('login', LoginView);

        // Passwort Wiederherstellung
        this.addViewRoute('recover', RecoverView);

        // Register  Route hinzufügen
        this.addViewRoute(['register', 'register/:any'], RegisterView);

        // Hauptansicht Route hinzufügen
        this.addViewRoute(['main', 'main/:any'], MainView);

        // 404 Seite
        this.addViewRoute(['404', '404/'], FileNotFoundView);

        // Router starten
        Router.startWatcher();
    }

    addViewRoute(path: string, viewClass: any): void
    addViewRoute(path: string[], viewClass: any): void
    addViewRoute(path: string | string[], viewClass: any): void {
        /*
         * Route zu einr View registrieren
         */
        Router.addRoute(path, (subRoute: string) => {
            this.switchView(viewClass, subRoute);
        });
    }

    switchView(viewClass: typeof View, subRoute: string): void {
        /*
         * Umschaltung zwischen Ansichten
         */
        if (this.currentView) {
            if (this.currentView.constructor == viewClass) {
                this.currentView.show(subRoute);
            } else {
                this.currentView.dispose();
                this.currentView = null;
            }
        }

        if (!this.currentView) {
            this.currentView = new viewClass();
            this.currentView.init(subRoute);
        }
    }

    getCurrentView(): View {
        return this.currentView;
    }

    initLanguage() {
        $('.lang-menu .lang a')
            .toggleClass('active', false)
            .filter(`.${i18next.language}`)
            .toggleClass('active', true);
        $('.lang-menu .lang').on('click', 'a', function(evt: Event) {
            const lang = $(evt.currentTarget).attr('data-lang');
            evt.preventDefault();
            setNewLanguage(lang);
        });
    }

    initFooterScroll() {
        if (window.navigator && navigator.userAgent) {
            if (navigator.userAgent.indexOf('Trident') >= 0) {
                // nur im IE erforderlich (Trident = IE)
                $(window).on('scroll', () => {
                    let $footer = $('.footer');
                    let height = Math.floor($footer.height());
                    let top = Math.floor($footer.position().top);
                    let docHeight = Math.floor($(document).height());
                    if (top < docHeight - height - 5) {
                        $footer.offset({ top: docHeight });
                    }
                });
            }
        }
    }
}

export default ViewManager;
