import Session from './session';
import * as Service from './service';
import i18next from 'i18next';
import Moment from 'moment';
import {
    Dimmer
} from './spinner';
import { AddWorkDays, ValidateDate } from './utils';

import RetoureDialog from '../templates/retoure.hbs';
import RetoureList from '../templates/retoure_list.hbs';
import { Holidays } from "./Holidays";

class Retoure {
    protected articleTabActive: boolean;
    protected items;
    protected $cart;
    protected userDataId: string;

    constructor() {
        this.articleTabActive = true;
    }

    init(client, user) {
        const userhash = btoa(user.username);
        this.userDataId = `retoure-${userhash || 0}`;

        if (user['native-client-id'] != client.id) {
            this.userDataId += client.id;
        }

        // Retoure aus localStorage laden
        this._load();

        this.update();
    }

    _load() {
        this.items = Session.getUserData(this.userDataId) || this.items;
        if (!(this.items instanceof Object)) {
            this.items = {};
        }
    }

    _save() {
        Session.setUserData(this.userDataId, this.items);
    }

    addItem(articleNr: string, name?: string, count?: number, unit?: string, id?: number) {
        const tmpItem = this.items[articleNr];
        // TODO remember articles by id+amount additionally, to represent all orders
        if (tmpItem) {
            tmpItem.amount += (count || 1);
        } else if (name != null) {
            this.items[articleNr] = {
                "article-nr": articleNr.trim(),
                name: name.trim(),
                "amount": count,
                unit: unit.trim(),
                id,
                "show-remove-icon": true
            };
        }
        this._save();
        if (tmpItem) {
            // amount update
            this.updateItem(tmpItem);
        } else {
            // full update
            this.update();
        }
    }

    removeItem(articleNr: string, count: number = 1): void {
        const tmpItem = this.items[articleNr];
        if (tmpItem && tmpItem.amount > 0) {
            tmpItem.amount -= count;

            if (tmpItem.amount <= 0) {
                delete this.items[articleNr];
            }

            this._save();

            // amount update
            this.updateItem(tmpItem);
        }
    }

    deleteItem(articleNr: string) {
        let tmpItem = this.items[articleNr];
        if (tmpItem) {
            tmpItem.amount = 0;
            this.updateItem(tmpItem);
        }
        delete this.items[articleNr];
        this._save();
        this.update();
    }

    clear() {
        this.items = {};
        this._save();
        this.update();
    }

    getCount() {
        return Object.keys(this.items).length;
    }

    get(articleNr: string) {
        return this.items[articleNr];
    }

    getAll(): Array<any> {
        const result = [];
        for (var key in this.items) {
            if (this.items[key].amount) {
                result.push(this.items[key]);
            }
        }
        return result;
    }

    update() {
        if (this.$cart) {
            const products = this.getAll();
            const $listData = RetoureList({ data: products, 'show-remove-icon': true });
            this.$cart.find('.retoure-products').empty()
                .append($listData)
                .localize();
        }
    }

    updateItem(tmpItem) {
        this.$cart.find(`.order-products .row[data-article="${tmpItem['article-nr']}"] input.amount`).val(tmpItem['amount'] || 0);
        this.$cart.find(`.retoure-products .row[data-article="${tmpItem['article-nr']}"] input.amount`).val(tmpItem['amount'] || 0);
    }

    _updateOrderProducts(products: any[]) {
        const retoureData = this.items;
        if (retoureData) {
            for (let i = 0; i < products.length; i++) {
                const item = products[i];
                const retoureItem = retoureData[item['article-nr']];
                item.amount = retoureItem ? retoureItem.amount : 0;
            }
        }
        const $listData = RetoureList({ data: products });
        this.$cart.find('.order-products').empty()
            .append($listData);
    }

    show() {
        Holidays.load()
            .then((holidays) => {
                this._onShowDialog(null, holidays);
            }, () => {
                this._onShowDialog();
            })
    }

    _onShowDialog(evt?: Event, holidays?: any[]) {
        if (evt) {
            evt.stopPropagation();
            evt.preventDefault();
        }

        // show cart dialog
        this.$cart = this._createMessageWindow(false);
        this._bindCartEvents(this.$cart);

        this.$cart.find('[type="date"]').parent().calendar();

        this.$cart.find('div.checkbox').checkbox();
        this.$cart.find('.form').form({
            fields: {
                'pickup-date': ['empty']
            }
        });

        const $orders = this.$cart.find('.dropdown.orders');
        this._initDropdown(this.$cart, $orders);

        this.$cart.find('.menu .item').tab({
            onVisible: (tab) => {
                this.articleTabActive = tab == 'article';
                this._enableActiveTab(this.$cart);
            }
        });

        // get active tab
        const $activeTab = this.$cart.find('.tabular.menu .item.active');
        this.articleTabActive = $activeTab.attr('data-tab') == 'article';
        this._enableActiveTab(this.$cart);

        const disabledDays = [];
        if (holidays) {
            holidays.forEach(function(elem) {
                disabledDays.push({
                    date: new Date(elem.date),
                    message: (elem.message || '').replace(/(?:\r\n|\r|\n)/g, ' / ')
                });
            });
        }

        this.$cart.modal({
            onApprove: () => {
                // check active tab
                if (this.articleTabActive) {
                    this.articleTabActive = false;
                    this._enableActiveTab(this.$cart);
                    return false;
                }

                // prüfe Details Angaben auf Vollständigkeit
                if (!this.$cart.find('.form').form('validate form')) {
                    this.articleTabActive = false;
                    this._enableActiveTab(this.$cart);
                    return false;
                }

                const cartDetails = this.$cart.find('.form').form('get values');
                cartDetails['on-next-delivery'] = cartDetails['on-next-delivery'] == 'on';

                if (cartDetails['on-next-delivery'] === false &&
                    !ValidateDate(cartDetails['pickup-date'], disabledDays)) {
                    this.$cart.find('.form .field.pick-up').addClass('error');
                    return false;
                }

                // prüfe Inhalt des Warenkorbs, zeige warnung an
                const cartItems = this.getAll();
                let amount = 0;
                for (var i = 0; i < cartItems.length; i++) {
                    amount += cartItems[i].amount;
                }
                if (amount <= 0) {
                    this.$cart.find('.warning.message').removeClass('hidden');
                    return false;
                } else {
                    this.$cart.find('.warning.message').addClass('hidden');
                }

                // send order
                const dimmer = new Dimmer(this.$cart).show(i18next.t('retoure.sending'));
                // TODO Abholung Auftrag senden
                Service.requestPickup(cartItems, cartDetails)
                    .then((result) => {
                        if (!result) {
                            return $.Deferred().reject();
                        }
                        dimmer.hide();
                        // reset shopping cart
                        this.clear();
                        this.$cart.modal('hide');
                    })
                    .fail(() => {
                        // show error
                        dimmer.hide();
                        this.$cart.find('.negative.message').removeClass('hidden');
                    });
                return false;
            },
            onHidden: () => {
                // remove from body
                this.$cart.remove();
                this.$cart = null;
            },
            onShow: () => {
                const dateInputs = this.$cart.find('.form [type="date"]');
                // change type to text to disable browser popup
                dateInputs.attr('type', 'text');
                const defaultDates = {
                    "days": ["S", "M", "D", "M", "D", "F", "S"],
                    "months": ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
                    "monthsShort": ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
                    "today": "Heute",
                    "now": "Jetzt",
                    "am": "AM",
                    "pm": "PM"
                };

                const client = Session.getUserData('client');
                let offsetDays = 2;
                // additional +3 days delivery delay for not austria clients
                if (!client || !client.country || client.country.toLowerCase() !== 'ch') {
                    offsetDays += 3;
                }

                dateInputs.parent().calendar({
                    type: 'date',
                    minDate: AddWorkDays(new Date(), offsetDays, disabledDays),
                    disabledDaysOfWeek: [0, 6],
                    disabledDates: disabledDays,
                    firstDayOfWeek: 1,
                    formatter: {
                        date: function(date: Date) {
                            return Moment(date).format('L');
                        }
                    },
                    parser: {
                        date: function(text: string) {
                            const dt = Moment(text, 'L');
                            return dt.isValid() ? dt.toDate() : null;
                        }
                    },
                    text: i18next.t('calender', { returnObjects: true, defaultValue: defaultDates }),
                    onSelect: (date, mode) => {
                        this.$cart.find('textarea[name="customer-message"]').focus();
                    }
                });

                this.update();
            }
        })
            .modal('refresh')
            .modal('show');
    }

    _initDropdown($cart, $ordersDropdown) {
        const instance = this;
        // Init dropdown
        $ordersDropdown.dropdown({
            placeholder: i18next.t('retoure.orders'),
            values: [],
            onChange: function(value: string, text: string, $selectedItem) {
                Service.getProductsByOrder(value)
                    .then((products) => {
                        instance._updateOrderProducts(products);
                    });
            }
        });
        Service.getProductsOrders()
            .then(function(orders: Array<{ nr: number, date: string }>) {
                // prepare values
                const orderList = $.map(orders, function(order: { nr: number, date: string }) {
                    return {
                        name: `${order.nr} (${Moment(order.date).format('L')})`,
                        value: order.nr
                    };
                });
                $ordersDropdown.dropdown("change values", orderList);
            });
    }

    _enableActiveTab($cart) {
        $cart.find('.menu .item').tab('change tab', this.articleTabActive ? 'article' : 'details');
        $cart.find('.approve.button').text(this.articleTabActive ? i18next.t('retoure.continue') : i18next.t('retoure.send-order'));
    }

    _createMessageWindow(showPrompt) {
        let data = this.getAll();
        let $cart = $(RetoureDialog(data));
        $('body').append($cart);
        $cart.localize();
        return $cart;
    }

    _bindCartEvents($cart) {
        const self = this;

        $cart.on('click', '.button.subtract', function(evt) {
            // remove 1 piece
            const $row = $(evt.target).closest('.row');
            const articleNr = $row.attr('data-article');
            const $input = $row.find('input');
            let tmpVal = $input.val();
            if (tmpVal > 0) {
                tmpVal--;
                $input.val(tmpVal);
                if (!tmpVal) {
                    const $realRow = $cart.find(`.retoure-products [data-id="${$row.data('id')}"]`)
                    if ($realRow.length) {
                        $realRow.transition({
                            animation: 'fade right',
                            onComplete: () => {
                                self.removeItem(articleNr);
                            }
                        });
                    }
                } else {
                    self.removeItem(articleNr);
                    $input.transition('pulse', '100ms');
                }
            }
        });

        $cart.on('click', '.button.add', function(evt: Event) {
            // add 1 piece
            const $target = $(evt.target);
            const $row = $target.closest('.row');
            const articleNr = $row.attr('data-article');
            const articleID = $row.attr('data-id');
            const name = $row.find('>.name.column').text();
            const unit = $row.find('>.name.unit').text();
            const $input = $target.closest('.grid').find('input');
            let tmpVal = $input.val();
            tmpVal++;
            self.addItem(articleNr, name, 1, unit, articleID);
            $input.val(tmpVal);

            $input.transition('pulse', '100ms');
        });

        $cart.on('change', 'input.amount', function(evt) {
            const id = $(evt.target).closest('.row').attr('data-article');
            const tmpVal = Math.max(0, +evt.target.value);

            const $target = $(evt.target);
            const $row = $target.closest('.row');
            const articleNr = $row.attr('data-article');
            const articleID = $row.attr('data-id');
            const name = $row.find('>.name.column').text();
            const unit = $row.find('>.name.unit').text();
            const $input = $target.closest('.grid').find('input');

            const item = self.get(id);
            if (item) {
                const diff = tmpVal - item.amount;
                if (diff > 0) {
                    self.addItem(articleNr, name, diff, unit, articleID);
                } else {
                    if (tmpVal <= 0) {
                        const $realRow = $cart.find(`.retoure-products [data-id="${$row.data('id')}"]`);
                        $realRow.transition({
                            animation: 'fade right',
                            onComplete: () => {
                                self.removeItem(articleNr, Math.abs(diff));
                            }
                        });
                        return;
                    } else {
                        self.removeItem(articleNr, Math.abs(diff));
                    }
                }
            } else {
                self.addItem(articleNr, name, tmpVal, unit, articleID);
            }

            $input.val(tmpVal);

            $input.transition('pulse', '100ms');
        });

        $cart.on('click', '.button.delete', function(evt) {
            // remove item from cart
            const $row = $(evt.target).closest('.row');
            const articleNr = $row.attr('data-article');
            $row.transition({
                animation: 'fade right',
                onComplete: () => {
                    self.deleteItem(articleNr);
                    $row.remove();
                }
            });
        });

        $cart.find('div.checkbox.on-next-delivery input').on('change', function() {
            $cart.find('.details .field.pick-up').toggleClass('disabled', this.checked)
                .toggleClass('required', !this.checked);
            if (this.checked) {
                $cart.find('.form').form('remove rule', 'pickup-date', ['empty']);
            } else {
                $cart.find('.form').form('add rule', 'pickup-date', ['empty']);
            }
        });
    }
}

const RetoureInstance = new Retoure();
export default RetoureInstance;
