<div class="ui modal">
    <i class="close icon"></i>
    <div class="content">
        <div class="ui items description">
            <div class="item">
                <i class="huge halag-red-text {{document_icon current.filename}} file outline icon"></i>
                <div class="content">
                    <a class="header filename">{{current.filename}}</a>
                    <div class="meta">
                        <span data-i18n="upload.version">Version</span>: {{current.version}} / <span data-i18n="upload.last-change">Änderungsdatum</span>: {{date_format current.filedate}}
                    </div>
                    <div class="extra">
                        {{file_size current.size}}
                    </div>
                </div>
                <div class="ui huge float right basic circular icon download button" title="Datei herunterladen" data-i18n="[title]portal.docs.download-file" data-uid="{{current.unique-file-name}}">
                    <i class="big download icon"></i>
                </div>
            </div>
            {{#if current.comment}}
                <div>
                    <h6 class="ui top attached header" data-i18n="upload.description">Beschreibung</h6>
                    <div class="ui attached segment">
                        {{current.comment}}
                    </div>
                </div>
            {{/if}}
        </div>
    </div>
    {{#if versions}}
        <div class="scrolling content">
            <div class="ui red  segment">
                <h6 class="ui top header" data-i18n="upload.version_plural">Versionen</h6>
                <div class="ui divider"></div>
                <div class="file-listing">
                    <table class="ui very basic collapsing selectable table" style="width:100%">
                        <tbody class="version-list">
                            <!-- Detaillierte Dateiauflistung -->
                            {{#each versions}}
                                <tr class="item" data-uid="{{file-id}}">
                                    <td>
                                        <i class="big halag-red-text {{document_icon filename}} file outline icon"></i>
                                    </td>
                                    <td>
                                        <h4 class="ui header">
                                            <div class="content">
                                                {{filename}}
                                                <div class="sub header" style="padding-top: 10px;">{{comment}}</div>
                                            </div>
                                        </h4>
                                    </td>
                                    <td>
                                        {{date_format filedate}}
                                    </td>
                                    <td>
                                        {{file_size size}}
                                    </td>
                                    <td>
                                        {{version}}
                                    </td>
                                    <td>
                                        {{#if user-info}}
                                        {{user-info.last-name}}
                                        {{else}}
                                        -/-
                                        {{/if}}
                                    </td>
                                    <td>
                                        <div class="ui basic circular icon download button" title="Datei herunterladen" data-i18n="[title]portal.docs.download-file" data-uid="{{unique-file-name}}">
                                            <i class="download icon"></i>
                                        </div>
                                    </td>
                                </tr>
                            {{/each}}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    {{/if}}
    <div class="actions">
        <div class="ui labeled icon halag-red approve delete button" data-i18n="upload.delete">
            <i class="icon trash"></i>
            Löschen
        </div>
        <div class="ui black cancel button" data-i18n="upload.close">
            Schließen
        </div>
    </div>
</div>
