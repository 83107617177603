//
export class Dimmer {
    protected $dimmer;
    protected removeOnHide: boolean;

    constructor(parent?) {
        this.$dimmer = this._getDimmer(parent);
    }

    show(text?: string) {
        this.$dimmer.find('.loader').toggleClass('text', !!text).text(text ? text : '');
        this.$dimmer.addClass('active');
        return this;
    }

    hide() {
        if (this.removeOnHide) {
            this.$dimmer.remove();
        } else {
            this.$dimmer.removeClass('active');
        }
    }

    remove() {
        this.$dimmer.remove();
    }

    _getDimmer($parent) {
        if (!$parent || $($parent).length === 0) {
            $parent = $('body');
        } else {
            this.removeOnHide = true;
        }
        let $dimmer = $parent.find('> .dimmer');

        if ($dimmer.length === 0) {
            $dimmer = $(
                '<div class="ui inverted dimmer">' +
                '<div class="ui text loader">Wird geladen...</div>' +
                '</div>');
            $parent.append($dimmer);
        }
        return $dimmer;
    }
}

export class Spinner {
    protected $spinner;
    protected removeOnHide: boolean;

    constructor(parent?) {
        this.$spinner = this._getLoader(parent);
    }

    show(text?: string) {
        this.$spinner
            .toggleClass('text', !!text)
            .text(text ? text : '')
            .addClass('active');
        return this;
    }

    hide() {
        if (this.removeOnHide) {
            this.$spinner.remove();
        } else {
            this.$spinner.removeClass('active');
        }
    }

    _getLoader($parent) {
        if (!$parent || $($parent).length === 0) {
            $parent = $('body');
        } else {
            this.removeOnHide = true;
        }

        let $loader = $parent.find('> .loader');
        if ($loader.length === 0) {
            $loader = $('<div class="ui loader"></div>');
            $parent.append($loader);
        }
        return $loader;
    }
}

// dimmer with loader for whole Page
let PageSpinner = new Dimmer();
export default PageSpinner;
