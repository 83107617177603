<div class="ui cart message modal">
    <div class="header"><i class="reply icon"></i><span data-i18n="retoure.title">Retoure volle Ware</span></div>
    <div class="ui top attached tabular menu">
        <div class="active item" data-tab="article" data-i18n="retoure.article">Retoure-Artikel</div>
        <div class="item" data-tab="details" data-i18n="retoure.details">Details</div>
    </div>
    <div class="ui bottom attached active tab segment" data-tab="article">
        <div class="content">
            <div class="ui aligned grid">
                <div class="row">
                    <div class="four wide middle aligned column header">
                        <span data-i18n="retoure.from-order">Aus Bestellung</span>:
                    </div>
                    <div class="ten wide column">
                        <div class="ui search selection dropdown orders">
                            <input type="hidden" name="orders">
                            <i class="dropdown icon"></i>
                            <div class="default text" data-i18n="retoure.order">Bestellung</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="three wide column header" data-i18n="retoure.article-nr">
                        Artikel-Nr
                    </div>
                    <div class="seven wide column header" data-i18n="retoure.description">
                        Bezeichnung
                    </div>
                    <div class="three wide column header" data-i18n="retoure.quantity">
                        Menge
                    </div>
                    <div class="one wide column">
                    </div>
                    <div class="two wide column">
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="scrolling content" style="max-height: 250px; overflow: auto;">
            <div class="ui middle aligned items grid order-products">
                <span data-i18n="retoure.no-order-selected">Keine Bestellung ausgewählt.</span>
            </div>
        </div>
        <br />
        <h4 class="ui header" data-i18n="retoure.selected-items">Gewählte Retour Artikel</h4>
        <hr />
        <div class="scrolling content" style="max-height: 250px; overflow: auto;">
            <div class="ui middle aligned items grid retoure-products">
            </div>
        </div>
    </div>
    <div class="ui bottom attached tab segment" data-tab="details">
        <div class="ui form details">
            <div class="field">
                <label class="required" data-i18n="retoure.pickup-date">Abholtermin:</label>
                <div class="two fields">
                    <div class="required field pick-up">
                        <input type="date" name="pickup-date" />
                    </div>
                    <div class="field">
                        <div class="ui checkbox on-next-delivery">
                            <input name="on-next-delivery" type="checkbox">
                            <label data-i18n="retoure.on-next-delivery">Rückgabe bei nächster Lieferung</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field">
                <label data-i18n="retoure.your-message">Ihre Nachricht</label>
                <textarea name="customer-message" rows="3"></textarea>
            </div>
        </div>
    </div>
    <div class="">
        <div class="ui warning message">
            <p data-i18n="retoure.conditions">
                Diese Retouren-Anmeldung bezweckt den Rücktransport von voller Ware zur Halag. Gerne prüfen wir diese und kontaktieren Sie für das weitere Vorgehen.
            </p>
        </div>
        <div class="ui negative icon message hidden">
            <i class="ui exclamation icon"></i>
            <div class="content">
                <div class="header" data-i18n="retoure.error-send">
                    Fehler beim Senden der Retoure aufgetreten!
                </div>
            </div>
        </div>
        <div class="ui warning icon message hidden">
            <i class="ui warning circle icon"></i>
            <div class="content">
                <div class="header" data-i18n="retoure.empty-warning">
                    Ihre Retoure Liste ist leer!
                </div>
                <p data-i18n="retoure.check-quantity">Prüfen Sie evtl. die Menge.</p>
            </div>
        </div>
    </div>
    <div class="actions">
        <div class="ui cancel button" data-i18n="retoure.cancel">
            Abbrechen
        </div>
        <div class="ui green approve icon button" data-i18n="retoure.send-order">
            Absenden
            <i class="ui right chevron icon"></i>
        </div>
    </div>
</div>
