import i18next from 'i18next';

export function TranslateErrors() {

    /* Texte */
    let text = [
        'unspecifiedRule',
        'unspecifiedField'
    ];

    for (let i = 0; i < text.length; i++) {
        let key = text[i];
        $.fn.form.settings.text[key] = i18next.t(`semantic-ui.${key}`);
    }


    /* Prompt */
    let prompt = [
        'empty',
        'checked',
        'email',
        'url',
        'regExp',
        'integer',
        'decimal',
        'number',
        'is',
        'isExactly',
        'not',
        'notExactly',
        'contain',
        'containExactly',
        'doesntContain',
        'doesntContainExactly',
        'minLength',
        'length',
        'exactLength',
        'maxLength',
        'match',
        'different',
        'minCount',
        'exactCount',
        'maxCount'
    ];

    for (let i = 0; i < prompt.length; i++) {
        let key = prompt[i];
        $.fn.form.settings.prompt[key] = i18next.t(`semantic-ui.${key}`);
    }
}