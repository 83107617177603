<div class="ui mini modal">
    <div class="ui inverted dimmer">
        <div class="ui text loader" data-i18n="upload.progress-upload">Upload...</div>
    </div>

    <div class="content">
        <span data-i18n="[html]portal.reg.accept-terms-dialog">
            Um die Nutzung des Kundenportals fortsetzen zu können, müssen Sie die <a target="_blank" href="http://www.halagchemie.ch/agb/">AGB</a> gelesen und akzeptiert haben.
        </span>
        <br />
        <br />
        <div class="ui checkbox">
            <input type="checkbox">
            <label><a target="_blank" href="http://www.halagchemie.ch/agb/" data-i18n="portal.reg.accept-terms">Ich habe die AGB gelesen und stimme zu</a></label>
        </div>
    </div>
    <div class="actions">
        <div class="ui black deny button" data-i18n="upload.cancel">
            Abbrechen
        </div>
        <div class="ui halag-red disabled positive button">
            <span data-i18n="upload.save">Weiter</span>
        </div>
    </div>
</div>
