<tr class="item distinct" data-id="{{id}}">
    <td class="column">
        <i class="large halag-red-text icon cube"></i>
    </td>
    <td class="column">
        <h4 class="ui header">
          <div class="content">
              <span class="article-name">
                  {{name}}
              </span>
              <div class="sub header last_change">
                  <span data-i18n="portal.products.article-nr">Artikel-Nr</span>: <span class="article-nr">{{article-nr}}</span>
              </div>
        </div>
      </h4>
    </td>
    <td class="column doc" data-type="sdb">
        {{#if (hasXXDocument datasheets 'SDB')}}
        <a href="{{getXXDocumentUrl datasheets 'SDB'}}" class="ui min-size label" target="_blank" title="Sicherheitsdatenblatt" data-i18n="[title]portal.products.sdb">
            <i class="warning sign icon"></i><span>SDB</span>
        </a>
        {{/if}}
    </td>
    <td class="column doc" data-type="pb">
        {{#if (hasXXDocument datasheets 'PB')}}
        <a href="{{getXXDocumentUrl datasheets 'PB'}}" class="ui min-size label" target="_blank" title="Produktbeschreibung" data-i18n="[title]portal.products.pb">
            <i class="info circle icon"></i> PB
        </a>
        {{/if}}
    </td>
    <td class="column doc"  data-type="ba">
        {{#if (hasXXDocument datasheets 'BA')}}
        <a href="{{getXXDocumentUrl datasheets 'BA'}}" class="ui min-size label" target="_blank" title="Betriebsanweisung" data-i18n="[title]portal.products.ba">
            <i class="lab icon"></i> BA
        </a>
        {{/if}}
    </td>
    <td>
        {{#unless out-of-sale}}
        <div class="ui basic circular icon button in-cart">
            <i class="Shopping Basket icon"></i>
        </div>
        {{/unless}}
    </td>
</tr>
