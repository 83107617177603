import SubView from './sub_view';
import * as Service from '../service';
import Message from '../message';
import {
    Dimmer
} from '../spinner';
import Pager from '../pager';
import ShoppingCart from '../shoppingcart';
import i18next from 'i18next';

import EmptyPreviewItem from '../../templates/empty_preview_item.hbs';
import ProductItem from '../../templates/product_item.hbs';
import ProductDistinctItem from '../../templates/product_distinct_item.hbs';

const docsPerPage = 10;

class ProductsView extends SubView {
    protected articleNr: any;
    protected searchFilter: any;
    protected docTypeFilter: any;
    protected currentOffset: number;
    protected distinctProducts: boolean;
    protected lastRequestParameters: { offset: number, docTypeFilter?: any, searchFilter?: any, distinctProducts?: boolean, articleNr?: any };

    constructor(container: any) {
        super(container);
    }

    init() {
        this.searchFilter = null;
        this.distinctProducts = false;
        this.lastRequestParameters = {
            offset: -1
        };

        this.$parent.pager = new Pager(this.$parent.find('.menu.pagination'));
        this.$parent.pager.setPage(0);
        this._bindEvents();
        this.$parent.find('.file-listing').css('max-height', 1000);
    }

    _bindEvents() {
        // filter list
        this.$parent.find('.button.search').on('click', $.proxy(this._onFilterProducts, this));
        this.$parent.find('.search input').on('keydown', (evt) => {
            if (evt.keyCode != 13) {
                return;
            }

            this._onFilterProducts(evt);
            evt.preventDefault();
        });

        // add selected item to cart
        this.$parent.find('.products-list').on('click', '.in-cart', $.proxy(this._onAddToCart, this));

        // page change
        this.$parent.pager.element.on('change', (evt, page, total) => {
            this._loadProducts(page * docsPerPage);
        });

        // condense products list
        this.$parent.on('change', '[name="distinct-products"]', (evt) => {
            this.distinctProducts = $(evt.currentTarget).is(':checked');
            this._loadProducts();
        });

        // just stop clicks on links
        this.$parent.on('click', 'a', (evt) => {
            evt.stopPropagation();
        });

        // filter by single articleNr
        this.$parent.on('click', '.products-list .item', (evt) => {
            // enable 'back' button
            this.$parent.find('.single-item-row').removeClass('hidden');
            // set current filter item
            this.articleNr = $(evt.currentTarget).find('.article-nr').text();
            let name = $(evt.currentTarget).find('.article-name').text();
            this.$parent.find('.single-item-row.article-name').text(name);

            this._loadProducts();
        });

        // return to full list
        this.$parent.on('click', '.single-item-row .return', (evt) => {
            this.articleNr = null;
            this.$parent.find('.single-item-row').addClass('hidden');

            this._loadProducts();
        });

        // download clicks
        this.$parent.find('.products-list').on('click', '.column.doc a', function() {
            Service.logStatisticsEvent('purchase', $(this).closest('.column.doc').data('type'));
        });
    }

    _unbindEvents() {
        this.$parent.off('click');
        this.$parent.off('change');
        this.$parent.pager.element.off('change');
        this.$parent.find('.button.search').off('click');
        this.$parent.find('.search input').off('keydown');
        this.$parent.find('.products-list').off('click');
    }

    _onShown() {
        this.distinctProducts = this.$parent.find('[name="distinct-products"]').is(':checked');

        this._loadProducts();
    }

    _loadProducts(offset?: number) {
        // just for the case, offset has not been set
        offset = offset || 0;

        if (this.lastRequestParameters.offset == offset &&
            this.lastRequestParameters.searchFilter == this.searchFilter &&
            this.lastRequestParameters.distinctProducts == this.distinctProducts &&
            this.lastRequestParameters.articleNr == this.articleNr) {
            // no need for update
            return;
        }

        this.lastRequestParameters.offset = offset;
        this.lastRequestParameters.searchFilter = this.searchFilter;
        this.lastRequestParameters.distinctProducts = this.distinctProducts;
        this.lastRequestParameters.articleNr = this.articleNr;

        // load corresponding view
        if (this.articleNr) {
            this._loadSingleArticelView(offset);
        } else if (this.distinctProducts) {
            this._loadDistinctView(offset);
        } else {
            this._loadDefaultView(offset);
        }
    }

    _loadDefaultView(offset) {
        const self = this;
        const $products = this.$parent;
        const docsDimmer = new Dimmer($products).show(i18next.t('portal.loading'));
        Service.getProducts(offset, docsPerPage, this.searchFilter)
            .fail(function(msg) {
                docsDimmer.hide();
                Message.show(i18next.t('portal.error'), msg.statusText + ':<br>' + msg.responseText);
            })
            .then(function(data, textStatus, response) {
                self.currentOffset = offset;
                const totalCount = response.getResponseHeader('X-Documents-Count');
                const fromToRange = response.getResponseHeader('X-Documents-Range');
                $products.pager.setPageCount(Math.ceil(totalCount * 1 / docsPerPage));

                const $list = $products.find('.products-list').empty();
                if (!data || !data.length) {
                    $list.append(EmptyPreviewItem());
                } else {
                    for (let i = 0; i < data.length; i++) {
                        $list.append(ProductItem(data[i]));
                    }
                }
                $list.localize();
                docsDimmer.hide();
            });
    }

    _loadDistinctView(offset) {
        const self = this;
        const $products = this.$parent;
        const docsDimmer = new Dimmer($products).show(i18next.t('portal.loading'));
        Service.getProductsDistinct(offset, docsPerPage, this.searchFilter)
            .fail(function(msg) {
                docsDimmer.hide();
                Message.show(i18next.t('portal.error'), msg.statusText + ':<br>' + msg.responseText);
            })
            .then(function(data, textStatus, response) {
                self.currentOffset = offset;
                const totalCount = response.getResponseHeader('X-Documents-Count');
                const fromToRange = response.getResponseHeader('X-Documents-Range');
                $products.pager.setPageCount(Math.ceil(totalCount * 1 / docsPerPage));

                const $list = $products.find('.products-list').empty();
                if (!data || !data.length) {
                    $list.append(EmptyPreviewItem());
                } else {
                    for (let i = 0; i < data.length; i++) {
                        $list.append(ProductDistinctItem(data[i]));
                    }
                }
                $list.localize();
                docsDimmer.hide();
            });
    }

    _loadSingleArticelView(offset) {
        const self = this;
        const $products = this.$parent;
        const docsDimmer = new Dimmer($products).show(i18next.t('portal.loading'));
        Service.getProductsByArticle(this.articleNr, offset, docsPerPage, this.searchFilter)
            .fail(function(msg) {
                docsDimmer.hide();
                Message.show(i18next.t('portal.error'), msg.statusText + ':<br>' + msg.responseText);
            })
            .then(function(data, textStatus, response) {
                self.currentOffset = offset;
                const totalCount = response.getResponseHeader('X-Documents-Count');
                const fromToRange = response.getResponseHeader('X-Documents-Range');
                $products.pager.setPageCount(Math.ceil(totalCount * 1 / docsPerPage));

                const $list = $products.find('.products-list').empty();
                if (!data || !data.length) {
                    $list.append(EmptyPreviewItem());
                } else {
                    for (let i = 0; i < data.length; i++) {
                        $list.append(ProductItem(data[i]));
                    }
                }
                $list.localize();
                docsDimmer.hide();
            });
    }

    _onFilterProducts(evt) {
        Service.logStatisticsEvent('purchase', 'search');

        this.searchFilter = $(evt.currentTarget).parent().find('input').val().toLowerCase();
        this._loadProducts(0);
    }

    _onAddToCart(evt) {
        let $item = $(evt.currentTarget).closest('.item');
        let id = $item.data('id');
        let unit = $item.find('span.unit').text().replace(/^\s+|\s+$/g, '');
        let articleNr = $item.find('span.article-nr').text().replace(/^\s+|\s+$/g, '');
        let articleName = $item.find('span.article-name').text().replace(/^\s+|\s+$/g, '');
        ShoppingCart.addItem(articleNr, articleName, 1, unit, id);
        evt.stopPropagation();
    }

    dispose() {
        this._unbindEvents();
    }
}

export default ProductsView;
