import Handlebars from 'handlebars/runtime';
import Moment from 'moment';
import {
    getDatasheetUrl
} from '../service.js';
import i18next from 'i18next';

export default function() {

    const units = [
        "B",
        "kB",
        "MB",
        "GB",
        "TB",
        "MAX"
    ];

    Handlebars.registerHelper('short_string', function(text, limit) {
        /*
         * Einen String auf die angegebene Anzahl der Zeichen kürzen
         */
        if (text && text.length > limit) {
            return new Handlebars.SafeString(text.substr(0, isNaN(limit) ? 20 : limit) + '...');
        }
        return new Handlebars.SafeString(text || "");
    });

    Handlebars.registerHelper('date_format', function(isodate) {
        /*
         * Datum formatieren im Format: 12. Juni 2016
         */
        if (!isodate) {
            return '--.--.--';
        }
        return Moment(isodate).format('ll');
    });

    Handlebars.registerHelper('document_icon', function(filename) {
        /*
         * Dateityp ermitteln und passendes Icon zurückgeben
         */
        console.log(filename);
        var idx = (filename || '').lastIndexOf('.');
        if (idx < 0) {
            return '';
        }
        var ext = filename.substr(idx, filename.length - 1);
        switch (ext.toLowerCase()) {
            case '.pdf':
                return 'pdf';
            case '.doc':
            case '.docx':
            case '.docm':
                return 'word';
            case '.xls':
            case '.xlsx':
            case '.xlsm':
            case '.xlsb':
                return 'excel';
            case '.txt':
                return 'text';
            case '.jpg':
            case '.png':
            case '.bmp':
                return 'image';
            default:
                return '';
        }
    });

    Handlebars.registerHelper('resolve_doctype', function(type) {
        /*
         * Dokumenttyp auflösen
         */
        if (!type) {
            return '--';
        }

        type = type.replace(/rü/g, 'rue');
        var result = i18next.t(`portal.products.type.${type}`);
        return result || '--';
    });

    Handlebars.registerHelper('file_size', function(filesize) {
        /*
         * Dateigröße in ein lesbares Format umwandeln
         */
        if (!filesize) {
            return '-- B';
        }

        let level = 0;
        while (filesize > 1024) {
            filesize /= 1024.0;
            level++;
        }
        const unit = units[Math.min(level, units.length - 1)];
        filesize = filesize.toFixed(2);
        return `${filesize} ${unit}`;
    });

    Handlebars.registerHelper('hasXXDocument', function(list, type, block) {
        /*
         * Datenblattyp auflösen
         */
        if (!list) {
            return false;
        }

        for (var i = 0; i < list.length; i++) {
            if (list[i].type.toUpperCase() == type) {
                return true;
            }
        }
        return false;
    });

    Handlebars.registerHelper('getXXDocumentUrl', function(list, type, block) {
        /*
         * Datenblattyp auflösen
         */
        if (!list) {
            return false;
        }

        for (var i = 0; i < list.length; i++) {
            if (list[i].type.toUpperCase() == type) {
                return getDatasheetUrl(list[i].id);
            }
        }
        return false;
    });

    Handlebars.registerHelper('eq', function(v1, v2) {
        /*
         * vergleich zweier Werte
         */
        return v1 == v2;
    });

    Handlebars.registerHelper('ifShowShoppingButton', function(item, options) {
        /*
         * Artikel-Nr auf unbestellbarkeit vergleichen
         * Kunden Anforderung von 200801
         */
        if (item && !isNaN(item['article-nr'])) {
            const articleNr = +item['article-nr'];
            if (articleNr >= 7010100 &&
                articleNr <= 7080100) {
                return options.inverse(this);
            }
        }

        return options.fn(this);
    });
}
