import js_cookie from 'js-cookie';

const STORAGE_NAME_LOGIN = 'session_login';
const STORAGE_NAME_IS_LOGGED_IN = 'session_is_logged_in';
const STORAGE_NAME_LANGUAGE = 'session_language';
const STORAGE_NAME_USERDATA = 'user_data';
const PREFERRED_CLIENT = 'preferred_client';

export class Session {
    protected b64auth: string;
    protected _isLoggedIn: boolean;
    protected userdata;

    constructor() {
        var f = 5;
        this.b64auth = js_cookie.get(STORAGE_NAME_LOGIN);
        this._isLoggedIn = !!this.b64auth;

        if (localStorage) {
            let tmp = localStorage.getItem(STORAGE_NAME_USERDATA);
            this.userdata = tmp ? JSON.parse(tmp) : null;
        }
        if (!this.userdata) {
            let tmp = js_cookie.getJSON(STORAGE_NAME_USERDATA);
            this.userdata = tmp || null;
        }
    }

    isLoggedIn() {
        return this._isLoggedIn;
    }

    setLoggedIn(enable) {
        this._isLoggedIn = enable;
    }

    setAuthString(b64auth, remember = false) {
        this.b64auth = b64auth;
        // im Cookie speichern
        if (this.b64auth) {
            js_cookie.set(STORAGE_NAME_LOGIN, this.b64auth, {
                expires: remember ? 365 : 1
            });
        } else {
            js_cookie.remove(STORAGE_NAME_LOGIN);
        }
    }

    getAuthString() {
        return this.b64auth;
    }

    setUserData(name, value) {
        this.userdata = this.userdata || {};
        this.userdata[name] = value;

        let tmpData = JSON.stringify(this.userdata);
        // im localStorage speichern
        if (localStorage) {
            localStorage.setItem(STORAGE_NAME_USERDATA, tmpData);
        }
        // im Cookie speichern
        js_cookie.set(STORAGE_NAME_USERDATA, tmpData);
    }

    public getUserData(name: string): any {
        return (this.userdata || {})[name];
    }

    getLanguage(): string {
        return this.getUserData(STORAGE_NAME_LANGUAGE);
    }

    setLanguage(lang: string) {
        this.setUserData(STORAGE_NAME_LANGUAGE, lang);
    }

    setPreferredClient(clientID: number) {
        if (isNaN(clientID)) {
            js_cookie.remove(PREFERRED_CLIENT);
        } else {
            js_cookie.set(PREFERRED_CLIENT, clientID);
        }
    }
}

const SessionInstance = new Session();
export default SessionInstance;
