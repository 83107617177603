import Session from './session';
import Moment from 'moment';

export function getAuthString(token: string): string
export function getAuthString(username: string, password: string): string
export function getAuthString(username_token: string, password?: string): string {
    /*
    * Erstellt ein String für Basic oder Token Authentifizierung
    */
    if (password != null) {
        return 'Basic ' + btoa(`${username_token}:${password}`);
    } else {
        return `Token ${username_token}`;
    }
}

﻿export function getUrlParameter(url: string): { FullPath: string, Url: string, Parameter: Array<string> } {
    /*
     * Trennt URL Parameter auf und gibt ein Objekt mit URL und Parameterliste zurück
     */
    if (!url) {
        return null;
    }

    var result = {
        FullPath: url,
        Url: null,
        Parameter: null
    };

    var tmpSplit = url.split('?');
    result.Url = tmpSplit[0];

    // Url Parameter weiter auftrennen
    if (tmpSplit.length > 1) {
        var parameterList = {};
        var paramSplit = tmpSplit[1].split('&');
        for (var i = 0; i < paramSplit.length; i++) {
            var keyVal = paramSplit[i].split('=');
            if (keyVal.length > 1) {
                parameterList[keyVal[0]] = keyVal[1];
            } else {
                parameterList[keyVal[0]] = null;
            }
        }
        result.Parameter = parameterList;
    }

    return result;
}

export function getParameterFromUrl(sParam: string): string {
    /*
     * Gibt den Wert eines URL-Parameters zurück
     */
    const sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&');

    for (let i = 0; i < sURLVariables.length; i++) {
        const sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? null : sParameterName[1];
        }
    }
}

export function setUrlParameter(name: string, value: string) {
    /*
     * Setzt den Wert eines URL-Parameters
     */
    name = encodeURI(name);
    value = encodeURI(value);

    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&');

    var hasSet = false;
    for (let i = 0; i < sURLVariables.length; i++) {
        const sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === name) {
            sParameterName[1] = value;
            sURLVariables[i] = sParameterName.join('=');
            hasSet = true;
            break;
        }
    }

    if (!hasSet) {
        sURLVariables[sURLVariables.length] = [name, value].join('=');
    }

    document.location.search = sURLVariables.join('&');
}

export function isUrl(url: string) {
    /*
     * Prüft ob die URL mit http:// oder https:// gebinnt
     */
    if (!url) {
        return null;
    }

    return url.startsWith('http://') || url.startsWith('https://');
}

export function isGuid(id: string) {
    /*
     * Prüft ob die URL mit http:// oder https:// gebinnt
     */
    if (!id) {
        return false;
    }

    return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(id);
}

export function isCordova() {
    /*
     * Prüft anhand URL ob es eine cordova / App Anwendung ist
     */
    return document.URL.indexOf('http://') === -1 && document.URL.indexOf('https://') === -1;
}

let _fileSystem = null;
export function getFileSystem() {
    /*
     * Fragt das Dateisystem an, gibt ein promise zurück
     */
    var reqDefer = $.Deferred();

    if (_fileSystem) {
        reqDefer.resolve(_fileSystem);
    } else {
        window.requestFileSystem(LocalFileSystem.PERSISTENT,
            0,
            (fs) => {
                reqDefer.resolve(fs);
            },
            (msg) => {
                reqDefer.reject(msg);
            }
        );
    }
    return reqDefer.promise();
}

export function getValidLink(link) {
    /*
     * Prüft ob 'externer' Link http:// vorgestellt hat und fügt bei Bedarf hinzu.
     */
    if (!link) {
        return null;
    }

    if (link.indexOf('http:/') === 0 || link.indexOf('https:/') === 0) {
        return link;
    }

    return 'http://' + link;
}

export function getCurrentLanguage(dflt?) {
    /*
     * Lädt unter Beachtung aller Quellen die Sprache
     * Sprachangabe über URL Parameter überschreibt die hinterlegte Sprache
     */
    return getParameterFromUrl('lang') || Session.getLanguage() || dflt || "de";
}

export function setNewLanguage(lang) {
    /*
     * Speichert die neu gewählte sprache ab
     */
    Session.setLanguage(lang);
    setUrlParameter('lang', lang);
}

export function prepareChrome() {
    /*
     * Wartet bis chrome das Dateisystem richtig initialisiert hat
     */

    // TODO check what is required

    var pluginReady = $.Deferred();
    var sizeReady = $.Deferred();

    // persistent storage erhöhen
    if (cordova.platformId === 'browser' &&
        typeof (window.initPersistentFileSystem) != 'undefined') {
        var bytes = 50 * 1024 * 1024;
        window.initPersistentFileSystem(bytes,
            () => {
                sizeReady.resolve();
            },
            () => {
                sizeReady.resolve();
            });
    } else {
        sizeReady.resolve();
    }

    // warten bis Dateisystem bereit
    if (!!window.isFilePluginReadyRaised) {
        window.addEventListener('filePluginIsReady', function() {
            pluginReady.resolve();
        }, false);
        if (window.isFilePluginReadyRaised()) {
            pluginReady.resolve();
        }
    } else {
        pluginReady.resolve();
    }

    return $.when.apply($, [pluginReady, sizeReady]);
}

export function AddWorkDays(date: Date, daysToAdd: number, disabledDays?: Array<{ date: Date }>) {
    date = new Date(date.getTime());
    date.setHours(0, 0, 0, 0);

    while (daysToAdd > 0) {
        const day = date.getDay();
        if (day != 0 && day != 6 && !(disabledDays || []).some((holiday: { date: Date }) => holiday.date.getTime() == date.getTime())) {
            daysToAdd--;
        }

        date.setDate(date.getDate() + 1);
    }

    return date;
}

export function ValidateDate(date: string | Date, disabledDays?: Array<{ date: Date }>): boolean {
    if (typeof date === 'string') {
        // 1st try
        let dt = Moment(date, 'L');
        if (!dt.isValid()) {
            // 2nd try
            dt = Moment(date);

            if (!dt.isValid()) {
                return false;
            }
        }

        date = <Date>dt.toDate();
    }

    const checkDate = new Date(date.getTime());
    checkDate.setHours(0, 0, 0, 0);
    const day = checkDate.getDay();

    return day != 0 && day != 6 && !(disabledDays || []).some((holiday: { date: Date }) => holiday.date.getTime() == checkDate.getTime());
}

// custom form validation rule
$.fn.form.settings.rules.isValidDate = function(value: string) {
    const dt = Moment(value, 'L');

    if (!dt.isValid()) {
        return false;
    }

    const date = <Date>dt.toDate();
    const day = date.getDay();

    // weekend as invalid time
    return day != 0 && day != 6;
};
