import View from './view';
import { Dimmer } from '../spinner';
import * as Service from '../service';
import Message from '../message';

import i18next from 'i18next';

import '../../less/register.less';

﻿class RegisterView extends View {

    protected $parent;
    protected currentView: string;

    init() {
        this.show();
    }

    show() {
        this.$parent = $('.main-content');
        this.$parent.load('templates/register.html', (evt) => {
            $(this.$parent).localize();
            this.currentView = 'base';
            this.$parent.find('.dropdown').dropdown();
            this.$parent.find('.checkbox').checkbox();
            this._initValidation();
            this._bindEvents();
        });
    }

    _initValidation() {
        this.$parent.find('.form.base-data')
            .form({
                keyboardShortcuts: false,
                revalidate: true,
                fields: {
                    'customer-id': 'empty',
                    'first-name': 'empty',
                    'last-name': 'empty',
                    'street': 'empty',
                    'house-nr': 'empty',
                    'postcode': ['minLength[3]', 'empty'],
                    'city': 'empty',
                    'contact-email': 'email',
                    'tel-nr': 'empty'
                }
            });

        this.$parent.find('.form.login-data')
            .form({
                keyboardShortcuts: false,
                revalidate: true,
                fields: {
                    'login-id': ['email', 'empty'],
                    'password': ['minLength[6]', 'empty'],
                    'password-confirm': 'match[password]'
                }
            })
            ;

        this.$parent.find('.form.summary-data')
            .form({
                revalidate: true,
                fields: {
                    'accept-license': {
                        identifier: 'accept-license',
                        rules: [{
                            type: 'checked',
                            prompt: i18next.t('portal.reg.check-license')
                        }]
                    }
                }
            })
            ;
    }

    _bindEvents() {
        const self = this;
        // switch view events
        this.$parent.find('.button.next, .button.back').on('click', function(evt) {
            if ($(this).hasClass('next')) {
                self.switchDialogView($(this).data('id'), 'next');
            } else {
                self.switchDialogView($(this).data('id'), 'back');
            }
        });

        // send registration event
        this.$parent.find('.button.send').on('click', function(evt) {
            if (!self.isSummaryValid()) {
                return;
            }
            self._sendRegistration();
        });

        this.$parent.find('.form').on('keydown', 'input', $.proxy(self._triggerEnter, self));

        // toggle password visibility
        const instance = this;
        this.$parent.find('div.password i.eye.icon').on('click', function(evt) {
            const $eye = instance.$parent.find('div.password i.eye.Icon');
            const passwordInput = $eye.siblings('input');
            if (passwordInput.attr('type') == 'password') {
                passwordInput.attr('type', 'text');
                $eye.addClass('slash');
            } else {
                passwordInput.attr('type', 'password');
                $eye.removeClass('slash');
            }
        });
    }

    _sendRegistration() {
        let self = this;
        let baseFormValues = self.$parent.find('.base-data.form').form('get values');
        let loginFormValues = self.$parent.find('.login-data.form').form('get values');
        let data = $.extend(true, baseFormValues, loginFormValues);

        const logDimmer = new Dimmer(self.$parent.find('.form')).show(i18next.t('portal.reg.register') + '...');
        Service.registerClient(data, (data) => {
            self.$parent.find('.step.summary').addClass('completed');
            self.$parent.find('.registration.row, .success-info.row').toggleClass('hidden');
            logDimmer.remove();
        }, (msg) => {
            self.$parent.find('.form').addClass('error');
            self.$parent.find('.message.error').text(msg.responseText);
            logDimmer.remove();
        });
    }

    _triggerEnter(evt) {
        if (evt.keyCode != 13) {
            return;
        }

        const $form = $(evt.currentTarget).closest('.form');
        const $inputsList = $form.find('input:not(.hidden,.noselection), button');
        const index = $inputsList.index(evt.currentTarget);

        if (index < $inputsList.length) {
            evt.preventDefault();
            evt.stopPropagation();
            $($inputsList.get(index + 1)).focus();
        }
    }

    switchDialogView(nextView: string, direction: string) {
        this._validateFormsOnSwitch(direction)
            .then(() => {
                this._changeView(nextView);
            });
    }

    _validateFormsOnSwitch(direction: string) {
        const deffer = $.Deferred();
        if (direction == 'next') {
            switch (this.currentView) {
                case 'base':
                    this.validateBaseData()
                        .then(() => {
                            const message = i18next.t('portal.reg.myOwnEmail');
                            return Message.prompt(null, message, () => {
                            }, () => {
                                setTimeout(() => {
                                    $('.base-data.form [name="contact-email"]').focus();
                                }, 50);
                            });
                        })
                        .then(() => {
                            this.$parent.find('.step.base').addClass('completed');
                            deffer.resolve();
                        });
                    break;

                case 'login':
                    this.validateLoginData()
                        .then(() => {
                            this.$parent.find('.step.login').addClass('completed');
                            deffer.resolve();
                        });
                    break;

                default:
                    deffer.resolve();
                    break;
            }
        } else {
            deffer.resolve();
        }
        return deffer.promise();
    }

    _changeView(nextView: string) {
        switch (nextView) {
            case 'base':
                this.$parent.find('.login-data').addClass('hidden');
                this.$parent.find('.summary-data').addClass('hidden');
                this.$parent.find('.base-data').removeClass('hidden');
                break;
            case 'login':
                this.$parent.find('.summary-data').addClass('hidden');
                this.$parent.find('.base-data').addClass('hidden');
                this.$parent.find('.login-data').removeClass('hidden');

                if (this.currentView === 'base') {
                    let email = $('.base-data.form').form('get value', 'contact-email');
                    this.$parent.find('.login-data input[name="login-id"]').val(email);
                }

                if (this.currentView === 'base') {
                    const fName = $('.base-data.form').form('get value', 'first-name');
                    const lName = $('.base-data.form').form('get value', 'last-name');
                    this.$parent.find('.login-data input[name="login-first-name"]').val(fName);
                    this.$parent.find('.login-data input[name="login-last-name"]').val(lName);
                }
                this.$parent.find('.login-data input[name="password"]').focus();
                break;
            case 'summary':
                this.$parent.find('.base-data').addClass('hidden');
                this.$parent.find('.login-data').addClass('hidden');
                this.$parent.find('.summary-data').removeClass('hidden');

                const baseFormValues = $('.base-data.form').form('get values');
                const loginFormValues = $('.login-data.form').form('get values');
                const summaryValues = $.extend(true, baseFormValues, loginFormValues);
                summaryValues.name = `${summaryValues['first-name'] || ''} ${summaryValues['last-name'] || ''}`;
                const $summaryTable = this.$parent.find('.summary-data table');
                const formKeys = Object.keys(summaryValues);
                for (let i = 0; i < formKeys.length; i++) {
                    const currentKey = formKeys[i];
                    const $field = $summaryTable.find(`tr.${currentKey} td.value`);
                    if ($field.length === 0) {
                        continue;
                    } else if (currentKey === 'street') {
                        $field.text(`${summaryValues.street} ${summaryValues['house-nr']}`);
                    } else if (currentKey === 'city') {
                        $field.text(`${summaryValues.postcode} ${summaryValues.city}`);
                    } else if (currentKey === 'country') {
                        const textItem = this.$parent.find('.base-data div.dropdown').dropdown('get item', summaryValues[currentKey]);
                        $field.text(!textItem ? '' : textItem.text());
                    } else {
                        $field.text(summaryValues[currentKey]);
                    }
                }
                break;
        }

        // Fortschritt setzen
        this.$parent.find('.step.active').removeClass('active');
        this.$parent.find(`.step.${nextView}`).addClass('active');
        this.currentView = nextView;
    }

    validateBaseData() {
        if (this.$parent.find('.base-data.form').form('validate form')) {
            return $.Deferred().resolve().promise();
        } else {
            return $.Deferred().reject().promise();
        }
    }

    validateLoginData() {
        if (!this.$parent.find('.login-data.form').form('validate form')) {
            return $.Deferred().reject().promise();
        }

        const logDimmer = new Dimmer(this.$parent.find('.login-data.form')).show(i18next.t('portal.verify'));
        const nameCheckDefer = $.Deferred();
        const userName = this.$parent.find('.login-data.form').form('get value', 'login-id');
        Service.checkUsername(userName)
            .then((userExists: boolean) => {
                if (userExists) {
                    return $.Deferred().reject().promise();
                }
                nameCheckDefer.resolve();
            })
            .fail(() => {
                this.$parent.find('.login-data.form').addClass('error');
                this.$parent.find('.error.message').text(i18next.t('portal.error.username-used-already'));
                nameCheckDefer.reject();
            })
            .always(() => {
                logDimmer.hide();
            });

        return nameCheckDefer.promise();
    }

    isSummaryValid() {
        return this.$parent.find('.summary-data.form').form('validate form');
    }

    dispose() {

    }
}

export default RegisterView;
