import SubView from './sub_view';
import {
    Dimmer
} from '../spinner';
import Session from '../session';
import * as Service from '../service';
import DownloadHelper from '../download_helper';

import i18next from 'i18next';
import Moment from 'moment';

import EmptyPreviewItem from '../../templates/empty_preview_item.hbs';
import DocumentPreviewItem from '../../templates/document_preview_item.hbs';
import UserFilePreviewItem from '../../templates/userfile_preview_item.hbs';
import ProductPreviewItem from '../../templates/product_preview_item.hbs';
import ProductInfoPreviewItem from '../../templates/productinfo_preview_item.hbs';

class HomeView extends SubView {
    protected productsData: { [x: string]: any; };

    constructor(container) {
        super(container);
    }

    init() {
        this._initDocumentsCard();
        this._initProductsCard();
        this._initCleaningPlansCard();
        this._initLabReportsCard();
        this._initMarketingInfosCard();
        this._initFileExchangeCard();

        this._bindEvents();
    }

    _initDocumentsCard() {
        const $docs = this.$parent.find('.card.documents');
        const docsDimmer = new Dimmer($docs).show(i18next.t('portal.loading'));
        Service.getLastXDocuments(10)
            .then(function(data) {
                const $list = $docs.find('.list').empty();
                if (!data || !data.length) {
                    $list.append(EmptyPreviewItem());
                } else {
                    let maxDate = '';
                    for (let i = 0; i < data.length; i++) {
                        $list.append(DocumentPreviewItem(data[i]));
                        if (data[i].lstchg > maxDate) {
                            maxDate = data[i].lstchg;
                        }
                    }
                    const $time = $docs.find('.time');
                    $time.text(Moment(maxDate).format('LL'));
                }
                $list.localize();
                docsDimmer.hide();
            });
    }

    _initProductsCard() {
        const $products = this.$parent.find('.card.products');
        const prodDimmer = new Dimmer($products).show(i18next.t('portal.loading'));
        const self = this;
        Service.getLastXProducts(10)
            .then(function(data) {
                const $list = $products.find('.list').empty();
                self.productsData = {};
                if (!data || !data.length) {
                    $list.append(EmptyPreviewItem());
                } else {
                    let maxDate = '0';
                    for (let i = 0; i < data.length; i++) {
                        const item = data[i];
                        self.productsData[item.id] = item;
                        const $product = $(ProductPreviewItem(item));
                        $list.append($product);
                        self._initPopup($product, item);
                        if (item['purchase-date'] > maxDate) {
                            maxDate = item['purchase-date'];
                        }
                    }
                    const $time = $products.find('.time');
                    $time.text(Moment(maxDate).format('LL'));
                }
                $list.localize();
                prodDimmer.hide();
            });
    }

    _initCleaningPlansCard() {
        const $plans = this.$parent.find('.card.cleaning-plans');

        let showCleaningPlans = true;
        const user = Session.getUserData('user-info');
        if (!user || user["is-cleaningplans-enabled"] === null) {
            const client = Session.getUserData('client');
            if (client && client.settings) {
                // keine Reinigungspläne anzeigen
                showCleaningPlans = !!client.settings["is-cleaningplans-enabled"];
            }
        } else {
            showCleaningPlans = user["is-cleaningplans-enabled"];
        }

        if (!showCleaningPlans) {
            $plans.addClass('hidden');
            return;
        }

        $plans.removeClass('hidden');
        const docsDimmer = new Dimmer($plans).show(i18next.t('portal.loading'));
        Service.getLastXCleaningPlans(10)
            .then(function(data) {
                const $list = $plans.find('.list').empty();
                if (!data || !data.length) {
                    $list.append(EmptyPreviewItem());
                } else {
                    let maxDate = '';
                    for (let i = 0; i < data.length; i++) {
                        $list.append(DocumentPreviewItem(data[i]));
                        if (data[i].lstchg > maxDate) {
                            maxDate = data[i].docdate;
                        }
                    }
                    const $time = $plans.find('.time');
                    $time.text(Moment(maxDate).format('LL'));
                }
                $list.localize();
                docsDimmer.hide();
            });
    }

    _initLabReportsCard() {
        const $plans = this.$parent.find('.card.lab-reports');
        const docsDimmer = new Dimmer($plans).show(i18next.t('portal.loading'));
        // TODO load lab reports
        Service.getLastXLabReports(10)
            .then(function(data) {
                const $list = $plans.find('.list').empty();
                if (!data || !data.length) {
                    $list.append(EmptyPreviewItem());
                } else {
                    let maxDate = '';
                    for (let i = 0; i < data.length; i++) {
                        $list.append(DocumentPreviewItem(data[i]));
                        if (data[i].lstchg > maxDate) {
                            maxDate = data[i].docdate;
                        }
                    }
                    const $time = $plans.find('.time');
                    $time.text(Moment(maxDate).format('LL'));
                }
                $list.localize();
                docsDimmer.hide();
            });
    }

    _initMarketingInfosCard() {
        const $productsInfo = this.$parent.find('.card.marketing-info');
        const docsDimmer = new Dimmer($productsInfo).show(i18next.t('portal.loading'));
        Service.getProductsInfos()
            .then(function(data) {
                const $list = $productsInfo.find('.list').empty();
                if (!data || !data.length) {
                    $list.append(EmptyPreviewItem());
                } else {
                    let clientBranch = (Session.getUserData('client') || {})['branch-of-industry'];

                    for (let i = 0; i < data.length; i++) {
                        const name = data[i].name;
                        const lstchg = data[i].lstchg;
                        const isnew = data[i].isnew;
                        $list.append(ProductInfoPreviewItem({
                            name,
                            lstchg,
                            url: Service.getProductInfoUrl(name, clientBranch),
                            isnew
                        }));
                    }
                    const $count = $productsInfo.find('.count');
                    $count.text(data.length);
                }
                $list.localize();
                docsDimmer.hide();
            })
            .fail(() => {
                const $list = $productsInfo.find('.list').empty();
                $list.append(EmptyPreviewItem());

                docsDimmer.hide();
            });
    }

    _initFileExchangeCard() {
        const $files = this.$parent.find('.card.file-exchange');

        let showUserfilesSharing = true;
        const user = Session.getUserData('user-info');
        if (!user || user["is-userfiles-sharing-enabled"] === null) {
            const client = Session.getUserData('client');
            if (client && client.settings) {
                // keinen Dateiaustausch anzeigen
                showUserfilesSharing = !!client.settings["is-userfiles-sharing-enabled"];
            }
        } else {
            showUserfilesSharing = user["is-userfiles-sharing-enabled"];
        }

        if (!showUserfilesSharing) {
            $files.addClass('hidden');
            return;
        }

        const docsDimmer = new Dimmer($files).show(i18next.t('portal.loading'));
        Service.getLastXUserFiles(10)
            .then(function(data) {
                const $list = $files.find('.list').empty();
                if (!data || !data.length) {
                    $list.append(EmptyPreviewItem());
                } else {
                    let maxDate = '';
                    for (let i = 0; i < data.length; i++) {
                        $list.append(UserFilePreviewItem(data[i]));
                        if (data[i].lstchg > maxDate) {
                            maxDate = data[i].docdate;
                        }
                    }
                    const $time = $files.find('.time');
                    $time.text(Moment(maxDate).format('LL'));
                }
                $list.localize();
                docsDimmer.hide();
            });
    }

    _initPopup($product, item) {
        const popupType = item.datasheets ? '.datasheet.popup' : '.empty.popup';
        const onShow = (item.datasheets && item.datasheets.length) ? $.proxy(this._onShowDatasheetPopup, this) : null;

        $product.find('.download.button').popup({
            popup: popupType,
            //hoverable: true,
            on: 'click',
            position: 'top right',
            onShow: onShow,
            lastResort: true
        });
    }

    _onShowDatasheetPopup(element) {
        const id = $(element).closest('.item').data('id');
        const item = this.productsData[id];
        if (item.datasheets) {
            // filter
            const buttons = this.$parent.find('.datasheet.popup .item');
            this.$parent.find('.datasheet.popup .header').text(item.name);
            buttons.addClass('hidden');
            for (let i = 0; i < item.datasheets.length; i++) {
                const sheet = item.datasheets[i];
                const type = sheet.type.toUpperCase();
                switch (type) {
                    case 'PB':
                        buttons.filter('.pb')
                            .removeClass('hidden')
                            .find('a').attr('href', Service.getDatasheetUrl(sheet.id));
                        break;
                    case 'BA':
                        buttons.filter('.ba')
                            .removeClass('hidden')
                            .find('a').attr('href', Service.getDatasheetUrl(sheet.id));
                        break;
                    case 'SDB':
                        buttons.filter('.sdb')
                            .removeClass('hidden')
                            .find('a').attr('href', Service.getDatasheetUrl(sheet.id));
                        break;
                }
            }
        }
    }

    _bindEvents() {
        const $docs = this.$parent.find('.card.documents');
        $docs.on('click', '.download.button', $.proxy(this._onDocumentDownload, this));

        const $plans = this.$parent.find('.card.cleaning-plans');
        $plans.on('click', '.download.button', $.proxy(this._onDocumentDownload, this));

        const $lab = this.$parent.find('.card.lab-reports');
        $lab.on('click', '.download.button', $.proxy(this._onDocumentDownload, this));

        var $files = this.$parent.find('.card.file-exchange');
        $files.on('click', '.download.button', $.proxy(this._onFileDownload, this));

        const $buttons = this.$parent.find('.datasheet.popup');
        $buttons.on('click', 'a.button', function(evt) {
            $(evt.currentTarget).closest('.datasheet.popup')
                .addClass('hidden')
                .removeClass('visible');
        });
    }

    _unbindEvents() {
        const $docs = this.$parent.find('.card.documents');
        $docs.off('click');
    }

    _onDocumentDownload(evt) {
        const uid = $(evt.currentTarget).closest('.item').data('uid');
        DownloadHelper.downloadDocument(uid);
    }

    _onFileDownload(evt) {
        const uid = $(evt.currentTarget).closest('.item').data('uid');
        DownloadHelper.downloadFile(uid);
    }

    dispose() {
        this._unbindEvents();
    }
}

export default HomeView;
