import SubView from './sub_view';
import Session from '../session';
import {
    Dimmer
} from '../spinner';
import * as Service from '../service';

import i18next from 'i18next';

class ContactView extends SubView {
    constructor(container) {
        super(container);
    }

    init() {
        const client = Session.getUserData('client');
        const user = Session.getUserData('user-info');
        const firstName = (user || client)['first-name'];
        const lastName = (user || client)['last-name'];
        const responseEmail = user ? user.username : client['contact-email'];

        this.$parent.find('.form').form({
            fields: {
                'name': 'empty',
                'contact-email': ['empty', 'email'],
                'message': 'empty'
            }
        }).form('set values', {
            'name': `${firstName} ${lastName}`,
            'contact-email': responseEmail,
            'message': ''
        });
        this.$parent.find('.send.message').addClass('hidden');

        this._bindEvents();
    }

    _bindEvents() {
        this.$parent.find('.contact.send.button').on('click', $.proxy(this._sendMessage, this));
    }

    _unbindEvents() {
        this.$parent.find('.contact.send.button').off('click');
    }

    _sendMessage() {
        const $sendButton = this.$parent.find('.send.button').addClass('hidden');
        const $resultMessage = this.$parent.find('.send.message').addClass('hidden');
        const $form = this.$parent.find('.form');
        if ($form.form('validate form')) {
            const $formArea = this.$parent.find('.contact-form.column');
            const logDimmer = new Dimmer($formArea).show(i18next.t('portal.send') + '...');
            const values = $form.form('get values');

            Service.logStatisticsEvent('contact', 'send');

            Service.sendMessage(values)
                .then((result: boolean) => {
                    if (!result) {
                        return $.Deferred().reject();
                    }
                    logDimmer.hide();
                    this.$parent.find('textarea[name="message"]').val('');
                    $resultMessage.removeClass('hidden')
                        .toggleClass('positive', true)
                        .toggleClass('error', false)
                        .find('.header').text(i18next.t('portal.contact.message-sent')).prepend('<i class="icon check"></i>');
                    // meldung ausblenden
                    setTimeout(function() {
                        $resultMessage.transition('scale', 2000, function() {
                            $resultMessage.addClass('hidden');
                            $sendButton.removeClass('hidden');
                        });
                    }, 2000);
                })
                .fail(() => {
                    logDimmer.hide();
                    $sendButton.removeClass('hidden');
                    $resultMessage.removeClass('hidden')
                        .toggleClass('positive', false)
                        .toggleClass('error', true)
                        .find('.header').text(i18next.t('portal.contact.error-send'));
                });
        } else {
            $sendButton.removeClass('hidden');
        }
    }

    dispose() {
        this._unbindEvents();
    }
}

export default ContactView;
