export default function Polyfill() {
    /*
     * Polyfill für isInteger
     */
    Number.isInteger = Number.isInteger || function(value) {
        return typeof value === "number" &&
            isFinite(value) &&
            Math.floor(value) === value;
    };

    /*
     * Hilfsmethode Polyfill
     */
    Array.isArray = Array.isArray || function(arg: any): arg is any[] {
        return (!!arg && arg.constructor === Array);
    };

    /*
     * Polyfill für padEnd
     */
    if (!String.prototype.padEnd) {
        String.prototype.padEnd = function padEnd(targetLength: number, padString?: string) {
            targetLength = targetLength >> 0; //floor if number or convert non-number to 0;
            padString = String(padString || ' ');
            if (this.length > targetLength) {
                return String(this);
            } else {
                targetLength = targetLength - this.length;
                if (targetLength > padString.length) {
                    padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
                }
                return String(this) + padString.slice(0, targetLength);
            }
        };
    }

    /*
     * Polyfill für padStart
     * https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
     */
    if (!String.prototype.padStart) {
        String.prototype.padStart = function padStart(targetLength: number, padString?: string) {
            targetLength = targetLength >> 0; //truncate if number, or convert non-number to 0;
            padString = String(typeof padString !== 'undefined' ? padString : ' ');
            if (this.length >= targetLength) {
                return String(this);
            } else {
                targetLength = targetLength - this.length;
                if (targetLength > padString.length) {
                    padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
                }
                return padString.slice(0, targetLength) + String(this);
            }
        };
    }

    /*
     * Polyfill für startsWith
     */
    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function(searchString, position) {
            position = position || 0;
            return this.indexOf(searchString, position) === position;
        };
    }
}
