import View from './view';
import Router from '../router';
import { Dimmer } from '../spinner';
import * as Service from '../service';
import Session from '../session';
import * as Utils from '../utils';
import ShoppingCart from '../shoppingcart';
import Retoure from '../retoure';

import AcceptConditionsDialog from '../../templates/accept_conditions_dialog.hbs';

import i18next from 'i18next';

class LoginView extends View {
    protected username: string;

    constructor(userName?: string) {
        super();
        this.username = userName;
    }

    init() {
        if (Session.isLoggedIn() && !this._getToken()) {
            Router.pushState('main');
            return;
        }
        this.show();
    }

    show() {
        const self = this;
        $('.main-content').load('templates/login.html', function(data) {
            self._initValidation();
            $(this).localize();

            $('.login-box button').on("click", self.checkLogin);
            $('input#username').val(self.username || '');
            $('.checkbox').checkbox();
            $('input#username').on('keydown', $.proxy(self._triggerEnter, self));
            $('input#password').on('keydown', $.proxy(self._triggerEnter, self))
                .siblings('i.eye.icon')
                .on('click', $.proxy(self._triggerPasswordVisibility, self));

            const token = self._getToken();
            if (token != null) {
                self.checkToken(token);
            }
        });
    }

    _triggerPasswordVisibility(evt) {
        const $eye = $(evt.currentTarget);
        const passwordInput = $eye.siblings('input');
        if (passwordInput.attr('type') == 'password') {
            passwordInput.attr('type', 'text');
            $eye.addClass('slash');
        } else {
            passwordInput.attr('type', 'password');
            $eye.removeClass('slash');
        }
    }

    _getToken(): string {
        return Utils.getParameterFromUrl('t') || null;
    }

    _initValidation() {
        $('.ui.login-box')
            .form({
                fields: {
                    username: ['email', 'empty'],
                    password: ['empty']
                }
            });
    }

    _triggerEnter(evt) {
        if (evt.keyCode != 13) {
            return;
        }

        if (evt.currentTarget.id === 'username') {
            evt.preventDefault();
            evt.stopPropagation();
            $('input#password').focus();
        } else if (evt.currentTarget.id === 'password') {
            evt.preventDefault();
            evt.stopPropagation();
            this.checkLogin();
        }
    }

    checkLogin(evt?) {
        if (!$('.form').form('validate form')) {
            return;
        }
        const logDimmer = new Dimmer($('.login-box')).show(i18next.t('portal.verify'));
        const loginValues = $('.form').form('get values');
        Service.login(loginValues.username, loginValues.password)
            .then(function(data) {
                if (!data) {
                    return $.Deferred().reject().promise();
                }

                return Service.isAcceptingConditionsRequired(Utils.getAuthString(data))
                    .then((requireAcceptConditions: boolean) => {
                        if (requireAcceptConditions) {
                            const acceptDeferred = $.Deferred();
                            const $dialog = $(AcceptConditionsDialog());
                            $dialog.localize();

                            $('.modal-container').append($dialog);

                            $dialog.modal({
                                autofocus: false,
                                closable: false,
                                dimmerSettings: {
                                    variation: 'inverted'
                                },
                                onShow: function() {
                                    $dialog.find('.checkbox').checkbox({
                                        onChange: function() {
                                            $dialog.find('.positive.button').toggleClass('disabled', !this.checked);
                                        }
                                    });

                                    logDimmer.hide();
                                },
                                onHidden: function() {
                                    $dialog.modal('destroy');
                                    $dialog.remove();
                                },
                                onApprove: function(element) {
                                    const accepted = $dialog.find('.checkbox').checkbox('is checked');

                                    if (!accepted) {
                                        acceptDeferred.reject('AGB wurden nicht akzeptiert.');
                                        return;
                                    }

                                    Session.setAuthString(Utils.getAuthString(data));

                                    Service.setUserConditionsAccepted(true)
                                        .then(acceptDeferred.resolve, acceptDeferred.reject)

                                    logDimmer.show();
                                },
                                onDeny: function(element) {
                                    acceptDeferred.reject();
                                }
                            }).modal('show');

                            return acceptDeferred;
                        }
                    })
                    .then(() => {
                        Service.logStatisticsEvent('login', 'login');

                        Session.setAuthString(Utils.getAuthString(data));
                        Session.setLoggedIn(true);
                        Session.setUserData("user", btoa(loginValues.username));
                        // clear cart on new login
                        ShoppingCart.clear();
                        Retoure.clear();
                    });
            })
            .then(() => { Router.pushState('main'); })
            .fail(function(msg) {
                $('.form').addClass('error');
                $('.form .message').text(msg ? msg.statusText : i18next.t('portal.error.check-your-login'));
            })
            .always(() => {
                logDimmer.hide();
            });
    }

    checkToken(token: string) {
        const logDimmer = new Dimmer($('.login-box')).show(i18next.t('portal.verify'));
        const loginValues = $('.form').form('get values');
        Service.login(token)
            .then(function(data) {
                if (!data) {
                    return $.Deferred().reject().promise();
                }

                Session.setAuthString(Utils.getAuthString(data));
                Session.setLoggedIn(true);
                Session.setUserData("user", btoa(loginValues.username));
                // clear cart on new login
                ShoppingCart.clear();
                Retoure.clear();
                Router.pushState('main', null, true);
            })
            .fail(function(msg) {
                $('.form').addClass('error');
                $('.form .message').text(msg ? msg.statusText : i18next.t('portal.error.check-your-login'));
            })
            .always(() => {
                logDimmer.hide();
            });
    }

    dispose() {

    }
}

export default LoginView;
