﻿const GUID_EXPRESSION = '([A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12})';
const INT_EXPRESSION = '(\\d+)';
const STRING_EXPRESSION = '([A-Z0-9\\-]+)';
const BOOLEAN_EXPRESSION = '(true|false)';
const ANY_EXPRESSION = '(.*)';

/* Unterstützte Platzhalter
 * :guid
 * :int
 * :string
 * :boolean
 * :*
 * ?
 */
class Router {

    protected _ignoreHashchange: boolean;
    protected _routes;
    protected _isWatching: boolean;
    protected _previousFragment: string;
    protected _currentFragment: string;

    constructor() {
        this._routes = {};
        this._isWatching = false;
        this._previousFragment = null;
        this._currentFragment = null;
        this._ignoreHashchange = false;
    }

    addRoute(routes, handler) {
        if (!Array.isArray(routes)) {
            routes = [routes];
        }
        for (let i = 0; i < routes.length; i++) {
            if (!this._routes.hasOwnProperty(routes[i])) {
                this._routes[routes[i]] = handler;
            }
        }
    }

    startWatcher() {
        var self = this;
        if (!this._isWatching) {
            $(window).on('hashchange', () => {
                self._onHashChange();
            }).trigger('hashchange');
            this._isWatching = true;
        }
    }

    getFragment(url?: string) {
        var fragment;

        if (!!url) {
            fragment = url.split('#')[1];
        } else if (!(fragment = window.location.hash.slice(1)) && !!window.location.search) {
            fragment = window.location.search;
        }

        return fragment || '/';
    }

    pushState(fragment: string, preventTrigger?: boolean, removeUrlParameter?: boolean) {
        if (!fragment) {
            return;
        }

        if (!fragment.startsWith('#')) {
            fragment = '#' + fragment;
        }

        // TODO check navigator
        history.pushState(null, (<any>navigator).title, (removeUrlParameter ? location.pathname : '') + fragment);

        this._ignoreHashchange = !!preventTrigger;

        $(window).trigger('hashchange');
    }

    restartState(fragment, preventTrigger) {
        if (!fragment) {
            return;
        }

        if (!fragment.startsWith('#')) {
            fragment = '#' + fragment;
        }

        var backlen = history.length - 1;
        history.go(-backlen); // Return at the beginning
        //history.replaceState({}, null, 'your relative url');

        history.replaceState(null, (<any>navigator).title, fragment);

        this._ignoreHashchange = preventTrigger;

        $(window).trigger('hashchange');
    }

    getState() {
        return location.hash.substring(1);
    }

    replaceState(fragment, preventTrigger) {
        if (!fragment) {
            return;
        }

        if (!fragment.startsWith('#')) {
            fragment = '#' + fragment;
        }

        history.replaceState(null, (<any>navigator).title, fragment);

        this._ignoreHashchange = preventTrigger;

        $(window).trigger('hashchange');
    }

    restoreFragment() {
        var tmp;

        if (!!this._previousFragment) {
            this.pushState(this._previousFragment, true);

            tmp = this._currentFragment;

            this._currentFragment = this._previousFragment;
            this._previousFragment = tmp;
        }
    }

    openDefaultRoute() {
        //   this.replaceState(this.getDefaultRoute());
    }

    resetState() {
        history.replaceState(null, <any>navigator, '#');
    }

    _getRouteExpression(str) {
        str = '^' + str
            .replace(/:guid/ig, GUID_EXPRESSION)
            .replace(/:int/ig, INT_EXPRESSION)
            .replace(/:string/ig, STRING_EXPRESSION)
            .replace(/:boolean/ig, BOOLEAN_EXPRESSION)
            .replace(/:any/ig, ANY_EXPRESSION)
            .replace(/[:][*]/ig, ANY_EXPRESSION)
            .replace(/\?/, '\\?') + '$';

        return new RegExp(str, 'ig');
    }

    _onHashChange() {
        var fragment = this.getFragment();
        var useDefaultRoute = true;
        var extractedValues;
        var typesOfValues;
        var params;
        var regEx;

        if (this._ignoreHashchange) {
            this._ignoreHashchange = false;
            return;
        }

        if (!!window.location.href) {
            this._previousFragment = this._currentFragment;
            this._currentFragment = this.getFragment(window.location.href);
        }

        for (var route in this._routes) {
            regEx = this._getRouteExpression(route);

            if (regEx.test(fragment)) {
                regEx.lastIndex = 0;

                typesOfValues = this._getValueTypes(route);
                extractedValues = regEx.exec(fragment);

                params = this._prepareValues(extractedValues, typesOfValues);

                this._routes[route].apply(this, params);

                useDefaultRoute = false;
                break;
            }
        }

        /*if (useDefaultRoute) {
            this.replaceState(this.getDefaultRoute());
        }*/
    }

    _getValueTypes(route) {
        var regEx = /:([\w]+)|:(.*)/ig;
        var types = [];
        var match;

        if (regEx.test(route)) {
            regEx.lastIndex = 0;

            while ((match = regEx.exec(route))) {
                types.push(match[1]);
            }
        }

        return types;
    }

    _prepareValues(extractedValues, types) {
        var values = [];
        var value;
        var type;

        if (extractedValues) {
            extractedValues.splice(0, 1);

            for (var vCnt = 0, vLen = extractedValues.length; vCnt < vLen; vCnt++) {
                value = extractedValues[vCnt];
                type = types[vCnt];

                switch (type) {
                    case 'int':
                        value = parseInt(value, 10);
                        break;
                    case 'boolean':
                        value = value === 'true';
                        break;
                }

                values.push(value);
            }
        }

        return values;
    }
}

export default (new Router());
