/*
* Grundklasse für eine View mit erforderlichen Funktionen
*/
class View {
    init(subRoute?: string) {
        throw new TypeError(`'${this.constructor.name}' class must implement method 'init(subRoute)'`);
    }
    show(subRoute?: string) {
        throw new TypeError(`'${this.constructor.name}' class must implement method 'show(subRoute)'`);
    }
    hide() {
        /* optional function */
        return $.Deferred().resolve();
    }
    dispose() {
        throw new TypeError(`'${this.constructor.name}' class must implement method 'dispose()'`);
    }
}

export default View;
