import * as Utils from './utils';
import Session from './session';﻿ //const serviceUrl = "http://sandersoftware.dyndns.org/restservice/";//accounts

export const ServiceUrl = 'api/';

export function login(token: string)
export function login(username: string, password?: string, onSuccess?, onError?)
export function login(username: string, password?: string, onSuccess?, onError?) {
    /*
     * Meldet sich am awenko Service an
     */
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Utils.getAuthString(username, password)
        },
        url: `${ServiceUrl}login/validate`,
        success: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        },
        error: (msg) => {
            if (onError) {
                onError(msg);
            }
        }
    });
}

export function logout() {
    /*
     * Meldet den Benutzer ab
     */
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}login/logout`,
        type: "POST",
        dataType: 'json'
    });
}

export function requestPasswordReset(username) {
    /*
     * Fordert das Zurücksetzen des Passwortes an
     */
    return $.ajax(`${ServiceUrl}login/requestPasswordReset?username=${username}`);
}

export function resetPasswordReset(username, password, token) {
    /*
     * Fordert das Zurücksetzen des Passwortes an
     */
    return $.ajax({
        type: "POST",
        url: `${ServiceUrl}login/resetPassword`,
        data: JSON.stringify({
            username,
            password,
            token
        }),
        dataType: 'json'
    });
}

export function registerClient(data, onSuccess, onError) {
    /*
     * Neuen Kunden anlegen
     */
    return $.ajax({
        type: "POST",
        url: `${ServiceUrl}client/create`,
        data: JSON.stringify(data),
        dataType: 'json',
        success: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        },
        error: (msg) => {
            if (onError) {
                onError(msg);
            }
        }
    });
}

export function checkUsername(username: string, onSuccess?: Function, onError?: Function) {
    /*
     * Prüft auf vorhandensein eines Benutzernamens
     */
    return $.ajax({
        type: "GET",
        url: `${ServiceUrl}login/userexists?username=${encodeURIComponent(username)}`,
        success: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        },
        error: (msg) => {
            if (onError) {
                onError(msg);
            }
        }
    });
}

export function getClientData(onSuccess?, onError?) {
    /*
     * Zugewiesenen Kundenbetreuer laden
     */
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}client/getCurrentClient`,
        success: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        },
        error: (msg) => {
            if (onError) {
                onError(msg);
            }
        }
    });
}

export function getAllowedClients(onSuccess?, onError?) {
    /*
     * Zugewiesenen Kundenaccounts laden
     */
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}client/getAllowedClients`,
        success: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        },
        error: (msg) => {
            if (onError) {
                onError(msg);
            }
        }
    });
}

export function updateUserInfo(data) {
    /*
     * Benutzerinformationen aktualisieren
     */
    return $.ajax({
        type: "POST",
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        data: JSON.stringify(data),
        dataType: 'json',
        url: `${ServiceUrl}user/updateUserInfo`,
    });
}

export function updateUserLogin(data) {
    /*
     * Benutzerinformationen aktualisieren
     */
    return $.ajax({
        type: "POST",
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        data: JSON.stringify(data),
        dataType: 'json',
        url: `${ServiceUrl}user/updateUserLogin`,
    });
}

export function updateClientInfo(data) {
    /*
     * Mandantinformationen aktualisieren
     */
    return $.ajax({
        type: "POST",
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        data: JSON.stringify(data),
        dataType: 'json',
        url: `${ServiceUrl}client/updateClientInfo`,
    });
}

export function getAssignedSupporter(onSuccess?, onError?) {
    /*
     * Zugewiesenen Kundenbetreuer laden
     */
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}supporter/getSupporter`,
        success: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        },
        error: (msg) => {
            if (onError) {
                onError(msg);
            }
        }
    });
}

export function getSupporterThumbUrl(fileId) {
    return encodeURI(`${ServiceUrl}supporter/getPhoto?fileId=${fileId}`);
}

export function getLastXCleaningPlans(count: number, onSuccess?: Function, onError?: Function) {
    /*
     * letzen X Reinigungspläne laden
     */
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}cleaningplan/lastEntries?num=${count}`,
        success: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        },
        error: (msg) => {
            if (onError) {
                onError(msg);
            }
        }
    });
}

export function getLastXLabReports(count: number, onSuccess?: Function, onError?: Function) {
    /*
     * letzen X Laborberichte laden
     */
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}labreports/lastEntries?num=${count}`,
        success: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        },
        error: (msg) => {
            if (onError) {
                onError(msg);
            }
        }
    });
}

export function getLastXUserFiles(count: number) {
    /*
     * letzen X Dateien laden
     */
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}files/lastEntries?num=${count}`
    });
}

export function getCleaningPlans(start: number, count: number, filter: string, docTypesFilter?: string) {
    /*
     * Bereich von Reinigungsplänen laden
     */
    count = isNaN(count) ? 50 : count;
    start = isNaN(start) ? 0 : start;
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}cleaningplan/getRange?start=${start}&count=${count}&filter=${filter || null}&docTypesFilter=${docTypesFilter || null}`
    });
}

export function getLabReports(start, count, filter) {
    /*
     * Bereich von Reinigungsplänen laden
     */
    count = isNaN(count) ? 50 : count;
    start = isNaN(start) ? 0 : start;
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}labreports/getRange?start=${start}&count=${count}&filter=${filter || null}`
    });
}

export function getCleaningPlansDownloadUrl(id) {
    /*
     * Stellt einen Link zum Download der Datei bereit
     */
    return encodeURI(`${ServiceUrl}cleaningplan/download?id=${id}`);
}

export function downloadCleaningPlan(id) {
    var deferred = new $.Deferred();
    var xhr = new XMLHttpRequest();
    var uri = `${ServiceUrl}cleaningplan/download?id=${id}`;
    xhr.open("GET", uri, true);
    xhr.responseType = 'blob';
    xhr.setRequestHeader("Authorization", Session.getAuthString());

    xhr.onreadystatechange = function(e) {
        if (this.readyState == 4 && this.status == 200) {
            var fileName = decodeURI(this.getResponseHeader('x-filename') || 'download');
            if (window.navigator['msSaveBlob']) {
                window.navigator['msSaveBlob'](this.response, fileName);
            } else {
                var file = window.URL.createObjectURL(this.response);
                var link = document.createElement('a');
                link.href = file;
                link.target = '_blank';
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                setTimeout(function() {
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(file);
                }, 100);
            }
            deferred.resolve();
        } else if (xhr.readyState === 4 && xhr.status !== 200) {
            deferred.reject(xhr.status);
        }
    };
    xhr.onprogress = function(e) {
        var currentProgress = e.loaded * 100 / e.total;
        deferred.progress(currentProgress);
    };

    xhr.send();

    return deferred.promise();
}

export function sendMessage(messageData) {
    /*
     * nachricht and den zugewiesenen Kundenbetreuer senden
     */
    return $.ajax({
        type: "POST",
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}supporter/sendMessage`,
        dataType: 'json',
        data: JSON.stringify(messageData)
    });
}

export function sendOrder(items, cartDetails) {
    /*
     * Bestellung and den zugewiesenen Kundenbetreuer senden
     */
    return $.ajax({
        type: "POST",
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}supporter/sendOrder`,
        dataType: 'json',
        data: JSON.stringify({
            items,
            cartDetails
        })
    });
}

export function requestPickup(items, details) {
    /*
     * Bestellung and den zugewiesenen Kundenbetreuer senden
     */
    return $.ajax({
        type: "POST",
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}supporter/requestPickup`,
        dataType: 'json',
        data: JSON.stringify({
            items,
            details
        })
    });
}

export function getLastXDocuments(count: number, onSuccess?: Function, onError?: Function) {
    /*
     * Die letzen {count} neuen Dateien laden
     */
    count = isNaN(count) ? 10 : count;
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}documentinfo/lastEntries?num=${count}`,
        success: (data) => {
            if (onSuccess) {
                onSuccess(data);
            }
        },
        error: (msg) => {
            if (onError) {
                onError(msg);
            }
        }
    });
}

export function getDocuments(start: number, count: number, filter: string, docTypesFilter?: string) {
    /*
     * Die letzen {count} neuen Dateien laden
     */
    count = isNaN(count) ? 50 : count;
    start = isNaN(start) ? 0 : start;
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}documentinfo/getRange?start=${start}&count=${count}&filter=${filter || null}&docTypesFilter=${docTypesFilter || null}`
    });
}

export function getDocumentDownloadUrl(uid) {
    /*
     * Stellt einen Link zum Download der Datei bereit
     */
    return encodeURI(`${ServiceUrl}documentinfo/downloadfile?uid=${uid}`);
}

export function getDocTypes() {
    /*
     * Alle verwendeten Dokument Typen laden
     */
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}documentinfo/getDocTypes`
    });
}

export function getCleaningPlanTypes() {
    /*
     * Alle verwendeten Reinigungsplan Typen laden
     */
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}cleaningplan/getCleaningPlanTypes`
    });
}

export function downloadDocument(uid: string) {
    return downloadX(`${ServiceUrl}documentinfo/downloadfile?uid=${uid}`);
}

export function downloadFile(uid: string) {
    return downloadX(`${ServiceUrl}files/downloadfile?uniqueFileName=${uid}`);
}

export function downloadX(uri: string) {
    const deferred = $.Deferred();
    const xhr = new XMLHttpRequest();
    xhr.open("GET", uri, true);
    xhr.responseType = 'blob';
    xhr.setRequestHeader("Authorization", Session.getAuthString());

    xhr.onreadystatechange = function(e: Event) {
        if (this.readyState == 4 && this.status == 200) {
            const fileName = decodeURI(this.getResponseHeader('x-filename') || 'download');
            if (window.navigator['msSaveBlob']) {
                window.navigator['msSaveBlob'](this.response, fileName);
            } else {
                const file = window.URL.createObjectURL(this.response);
                const link = document.createElement('a');
                link.href = file;
                link.target = '_blank';
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                setTimeout(function() {
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(file);
                }, 100);
            }
            deferred.resolve();
        } else if (xhr.readyState === 4 && xhr.status !== 200) {
            deferred.reject(xhr.status);
        }
    };
    xhr.onprogress = function(e) {
        const currentProgress = e.loaded * 100 / e.total;
        deferred.progress(currentProgress);
    };

    xhr.send();

    return deferred.promise();
}

export function getLastXProducts(count) {
    /*
     * Die letzen {count} neuen Produkte laden
     */
    count = isNaN(count) ? 10 : count;
    let lang = Utils.getCurrentLanguage();
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}productinfo/lastEntries?num=${count}&lang=${lang}`
    });
}

export function getProducts(start, count, filter) {
    /*
     * Die letzen {count} neuen Dateien laden
     */
    let lang = Utils.getCurrentLanguage();
    count = isNaN(count) ? 50 : count;
    start = isNaN(start) ? 0 : start;
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}productinfo/getProducts?start=${start}&count=${count}&lang=${lang}&filter=${filter || null}`
    });
}

export function getProductsDistinct(start, count, filter) {
    /*
     * Die letzen {count} neuen Dateien laden
     */
    let lang = Utils.getCurrentLanguage();
    count = isNaN(count) ? 50 : count;
    start = isNaN(start) ? 0 : start;
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}productinfo/getProductsDistinct?start=${start}&count=${count}&lang=${lang}&filter=${filter || null}`
    });
}

export function getProductsByArticle(articleNr, start, count, filter) {
    /*
     * Einträge zu einem bestimmten Artikel laden
     */
    let lang = Utils.getCurrentLanguage();
    count = isNaN(count) ? 50 : count;
    start = isNaN(start) ? 0 : start;
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}productinfo/getProductsByArticle?articleNr=${articleNr}&start=${start}&count=${count}&lang=${lang}&filter=${filter || null}`
    });
}

export function getProductsInfos() {
    /*
     * Produkt Broschüren laden
     */
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString(),
            'X-Portal-Language': Utils.getCurrentLanguage()
        },
        url: `${ServiceUrl}productinfo/getProductsInfos`
    });
}

export function checkFileExists(name: string) {
    /*
     * Prüft ob die Datei bereits existiert
     */
    return $.ajax({
        type: "GET",
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString(),
            'X-Portal-Language': Utils.getCurrentLanguage()
        },
        url: `${ServiceUrl}files/fileExists?filename=${encodeURIComponent(name)}`
    });
}

export function uploadFile(file: File, comment?: string) {
    const formdata = new FormData();    // Anlegen eines FormData Objekts zum Versenden unserer Datei
    formdata.append('uploadfile', file);  // Anhängen der Datei an das Objekt
    formdata.append("replace", "true");
    formdata.append("name", file.name); // Original Namen senden
    formdata.append("comment", encodeURIComponent(comment));

    return $.ajax({
        type: 'POST',
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString(),
            'X-Portal-Language': Utils.getCurrentLanguage()
        },
        url: `${ServiceUrl}files/upload`,
        data: formdata,
        processData: false,
        contentType: false
    });
}

export function getUserFiles(start: number, count: number, filter: string) {
    /*
     * Die letzen {count} neuen Dateien laden
     */
    count = isNaN(count) ? 50 : count;
    start = isNaN(start) ? 0 : start;

    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}files/getRange?start=${start}&count=${count}&filter=${filter || null}`
    });
}

export function getUserFileVersion(fileID: string) {
    /*
     * Versionen einer Datei laden
     */

    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}files/GetFileVersions?fileid=${fileID}`
    });
}


export function deleteUserFile(fileID: string) {
    /*
     * Vom Benutzer hochgeladene Datei löschen (inkl. aller Versionen)
     */
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}files/delete?fileID=${fileID}`,
        type: "POST",
        dataType: 'json',
        data: JSON.stringify({
            fileID
        })
    });
}

export function getDatasheetUrl(id) {
    /*
     * Stellt einen Link zum Download eines Datenblattes bereit
     */
    return encodeURI(`${ServiceUrl}productinfo/downloadDatasheet?id=${id}`);
}

export function getProductInfoUrl(name, branchOfIndustry) {
    /*
     * Stellt einen Link zum Download einer Produktbroschüre bereit
     */
    return encodeURI(`${ServiceUrl}productinfo/downloadProductsInfoFile?name=${encodeURIComponent(name)}&branch=${encodeURIComponent(branchOfIndustry)}`);
}

export function getProductsOrders() {
    /*
     * Die letzen Bestellungen laden
     */
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}productinfo/getLastOrders`
    });
}

export function getProductsByOrder(orderNr: string) {
    /*
     * Artikel der Bestellung laden
     */
    const lang = Utils.getCurrentLanguage();
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}productinfo/getProductsByOrder?orderNr=${orderNr}&lang=${lang}`
    });
}

export function setUserConditionsAccepted(isAccepted: boolean) {
    /*
     * AGB als akzeptiert deklarieren
     */
    return $.ajax({
        type: "POST",
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}user/setUserConditionsAccepted`,
        data: String(isAccepted),
        dataType: 'json'
    });
}

export function isAcceptingConditionsRequired(authString: string) {
    /*
     * Prüfen ob AGB zuerst akzeptiert werden müssen
     */
    return $.ajax({
        type: "GET",
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': authString
        },
        url: `${ServiceUrl}user/isAcceptingConditionsRequired`,
        dataType: 'json'
    });
}

export function logStatisticsEvent(module: string, action: string) {
    /*
     * Event für die Statistik erfassen
     */
    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}statistics/logEvent`,
        type: "POST",
        dataType: 'json',
        data: JSON.stringify({
            Module: module,
            Action: action
        })
    });
}

export function checkConnection() {
    let checkFinish = $.Deferred();

    let img = new Image();

    img.onload = () => {
        checkFinish.resolve();
    };
    img.onerror = () => {
        checkFinish.resolve();
    };
    img.src = ServiceUrl;

    setTimeout(() => {
        checkFinish.reject();
    }, 10000);

    return checkFinish.promise();
}

export function getRelevantHolidays() {
    /*
     * Alle relevanten Feiertage laden
     */
    const now = new Date();
    const tzoffset = now.getTimezoneOffset() * 60000;
    const startWith = (new Date(now.getTime() - tzoffset)).toISOString().split('T')[0];

    return $.ajax({
        xhrFields: {
            withCredentials: true
        },
        headers: {
            'Authorization': Session.getAuthString()
        },
        url: `${ServiceUrl}holiday/holidays?startWith=${startWith}`
    });
}
