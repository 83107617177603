<div class="item" data-uid="{{unique-file-name}}">
    <div class="right floated middle aligned content" style="margin-bottom: -35px;">
        <div class="ui basic circular icon download button"><i class="download icon"></i></div>
    </div>
    <i class="large halag-red-text {{document_icon filename}} file outline middle aligned icon"></i>
    <div class="content">
        <div class="header" title="{{name}}">{{{short_string filename 30}}}</div>
        <div class="description">{{date_format filedate}}</div>
    </div>
</div>
