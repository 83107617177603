<tr class="item" data-uid="{{file-id}}">
    <td>
        <i class="big halag-red-text {{document_icon filename}} file outline icon"></i>
    </td>
    <td>
        <h4 class="ui header">
            <div class="content">
                {{filename}}
                <div class="sub header" style="padding-top: 10px;">{{comment}}</div>
            </div>
        </h4>
    </td>
    <td>
        {{date_format filedate}}
    </td>
    <td>
        {{file_size size}}
    </td>
    <td>
        {{#if user-info}}
        {{user-info.last-name}}
        {{else}}
        -/-
        {{/if}}
    </td>
    <td>
        <div class="ui basic circular icon download button" title="Datei herunterladen" data-i18n="[title]portal.docs.download-file" data-uid="{{unique-file-name}}">
            <i class="download icon"></i>
        </div>
    </td>
</tr>
