import * as Service from './service';
import i18next from 'i18next';

class DownloadHelper {
    static downloadDocument(uid: string) {
        Service.logStatisticsEvent('documents', 'download');

        Service.downloadDocument(uid)
            .fail(function(resultCode: number) {
                switch (resultCode) {
                    case 401:
                    case 403:
                        alert(i18next.t('portal.no-rights-download'));
                        break;
                    case 404:
                        alert(i18next.t('portal.file-not-found'));
                        break;
                    default:
                        alert(i18next.t('portal.unexpected-error-occured'));
                }
            });
    }

    static downloadFile(uid: string) {
        Service.downloadFile(uid)
            .fail(function(resultCode: number) {
                switch (resultCode) {
                    case 401:
                    case 403:
                        alert(i18next.t('portal.no-rights-download'));
                        break;
                    case 404:
                        alert(i18next.t('portal.file-not-found'));
                        break;
                    default:
                        alert(i18next.t('portal.unexpected-error-occured'));
                }
            });
    }

    static downloadCleaningPlan(id) {
        Service.logStatisticsEvent('cleaning-plan', 'download');

        Service.downloadCleaningPlan(id)
            .fail(function(resultCode: number) {
                switch (resultCode) {
                    case 401:
                    case 403:
                        alert(i18next.t('portal.no-rights-download'));
                        break;
                    case 404:
                        alert(i18next.t('portal.file-not-found'));
                        break;
                    default:
                        alert(i18next.t('portal.unexpected-error-occured'));
                }
            });
    }
}

export default DownloadHelper;
