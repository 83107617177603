<div class="icon client-selection item">
    <i class="chalkboard teacher icon"></i>
    <i class="dropdown icon"></i>
    <span class="text" data-i18n="portal.client-account">Konto</span>
    <div class="menu transition hidden">

        {{#each this}}
            <a class="ui client icon item" data-id="{{id}}">
                <i class="user circle outline icon"></i>
                <span>{{company}}</span>
            </a>
        {{/each}}

    </div>
</div>
