import SubView from './sub_view';
import * as Utils from '../utils';
import Session from '../session';
import {
    Dimmer
} from '../spinner';
import * as Service from '../service';

import { setNewLanguage } from '../utils';
import i18next from 'i18next';

class SettingsView extends SubView {
    protected preEditClientData: any;

    constructor(container) {
        super(container);
    }

    init() {
        this.$parent.find('.form').form();
        this.$parent.find('.form > .field, .form > .fields').addClass('disabled');

        this._initUserInformation();
        this._initUserLoginInformation();
        this._initClientInformation();

        const client = Session.getUserData('client');
        this.$parent.find('.form').form({
            fields: {
                'name': 'empty',
                'contact-email': ['empty', 'email'],
                'message': 'empty'
            }
        }).form('set values', {
            'name': `${client['first-name']} ${client['last-name']}`,
            'contact-email': client['contact-email'],
            'message': ''
        });
        this.$parent.find('.send.message').addClass('hidden');

        this._bindEvents();
    }

    _initUserInformation(user?) {
        user = user || Session.getUserData('user-info');
        const language = user.language || Session.getLanguage() || 'de';

        this.$parent.find('[name="user[first-name]"]').val(user['first-name']);
        this.$parent.find('[name="user[last-name]"]').val(user['last-name']);
        this.$parent.find('.language.dropdown').dropdown('set selected', language.toLowerCase());
        this.$parent.find('[name="user[receive-notifications]"]').prop('checked', !!user['receive-notifications']);

        this.$parent.find('.user-data .edit.button').toggleClass('disabled', !user || user.supporter);
    }

    _initUserLoginInformation(username?: string) {
        username = username || atob(Session.getUserData("user"));

        this.$parent.find('[name="login[username]"]').val(username || "");
        this.$parent.find('[name="login[password1]"]').val("");
        this.$parent.find('[name="login[password2]"]').val("");

        this.$parent.find('.login-data .edit.button').toggleClass('disabled', !username);
    }

    _initClientInformation(client?) {
        client = client || Session.getUserData('client');
        this.$parent.find('[name="client[customer-id]"]').val(client['customer-id']);
        this.$parent.find('[name="client[company]"]').val(client['company']);
        this.$parent.find('[name="client[street]"]').val(client['street']);
        this.$parent.find('[name="client[house-nr]"]').val(client['house-nr']);
        this.$parent.find('[name="client[postcode]"]').val(client['postcode']);
        this.$parent.find('[name="client[city]"]').val(client['city']);

        const countryDropdown = this.$parent.find('.country.dropdown');

        if (!countryDropdown.find(`.menu [data-value="${client.country}"]`).length) {
            countryDropdown.find('.menu')
                .append(`<div class="item" data-value="${client.country}"><i class="${client.country} flag"></i>${(client.country || '').toUpperCase()}</div>`);
        }
        countryDropdown.dropdown({
            fullTextSearch: true,
            direction: 'upward'
        });
        countryDropdown.dropdown('set selected', client.country);
    }

    _bindEvents() {
        this.$parent.find('.edit.button').off('click').on('click', $.proxy(this._enableEditMode, this));
        this.$parent.find('.cancel.button').off('click').on('click', $.proxy(this._cancelEditMode, this));
        this.$parent.find('.save.button').off('click').on('click', $.proxy(this._saveChanges, this));

        // toggle password visibility
        this.$parent.find('div.password i.eye.icon').on('click', (evt) =>{
            const $eye = this.$parent.find('div.password i.eye.Icon');
            const passwordInput = $eye.siblings('input');
            if (passwordInput.attr('type') == 'password') {
                passwordInput.attr('type', 'text');
                $eye.addClass('slash');
            } else {
                passwordInput.attr('type', 'password');
                $eye.removeClass('slash');
            }
        });
    }

    _unbindEvents() {
        this.$parent.find('.contact.send.button').off('click');
    }

    _enableEditMode(evt) {
        const $editButton = $(evt.currentTarget);
        $editButton.toggleClass('hidden', true)
            .siblings('.button').toggleClass('hidden', false);
        $editButton.siblings('.field, .fields').toggleClass('disabled', false);

        // Werte vor der Änderung merken
        const $parentForm = $editButton.closest('.form');
        if ($parentForm.hasClass('client-data')) {
            const $form = this.$parent.find('.client-data.form');
            this.preEditClientData = this._cleanValues($form.form('get values'));
        }

        const firstInput = $editButton.siblings('.field, .fields').find('input:not([disabled]):not([readonly])').get(0);
        $(firstInput).focus();
    }

    _cancelEditMode(evt) {
        const $editButton = $(evt.currentTarget).siblings('.edit');
        $editButton.toggleClass('hidden', false)
            .siblings('.button').toggleClass('hidden', true);
        $editButton.siblings('.field, .fields').toggleClass('disabled', true);

        // TODO reset initial form values
        const $parentForm = $editButton.closest('.form');
        if ($parentForm.hasClass('user-data')) {
            this._initUserInformation();
        }
        else if ($parentForm.hasClass('login-data')) {
            this._initUserLoginInformation();
        }
        else if ($parentForm.hasClass('client-data')) {
            this._initClientInformation();
        }
    }

    _saveChanges(evt) {
        // TODO reset initial form values
        let saveDeferred;
        let $parentForm = $(evt.currentTarget).closest('.form');
        if ($parentForm.hasClass('user-data')) {
            saveDeferred = this._saveUserInformation();
        }
        else if ($parentForm.hasClass('login-data')) {
            saveDeferred = this._saveUserLoginInformation();
        }
        else if ($parentForm.hasClass('client-data')) {
            saveDeferred = this._saveClientInformation();
        }

        saveDeferred.then(() => {
            let $editButton = $(evt.currentTarget).siblings('.edit');
            $editButton.toggleClass('hidden', false)
                .siblings('.button').toggleClass('hidden', true);
            $editButton.siblings('.field, .fields').toggleClass('disabled', true);
        });
    }

    _cleanValues(item: any): any {
        const newItem = {};

        for (let field in item) {
            const start = field.indexOf('[') + 1;
            const end = field.lastIndexOf(']');
            const name = end < 0 ? field : field.substring(start, end);
            if (name == 'receive-notifications') {
                newItem[name] = item[field] == "on";
            } else {
                newItem[name] = item[field];
            }
        }
        return newItem;
    }

    _hasValueChanges(itemA: any, itemB: any): boolean {
        if (!itemA != !itemB) {
            return true;
        }

        const keysA: string[] = Object.keys(itemA);
        const keysB: string[] = Object.keys(itemB);
        if (keysA.length != keysB.length) {
            return true;
        }

        for (let i = 0; i < keysA.length; i++) {
            const key = keysA[i];
            const valA = itemA[key];
            const valB = itemB[key];
            if (!valA != !valB ||
                valA != valB) {
                return true;
            }
        }

        return false;
    }

    _saveUserInformation() {
        const $form = this.$parent.find('.user-data.form');
        if ($form.form('validate form')) {
            // TODO: speichern text
            const logDimmer = new Dimmer($form).show(i18next.t('portal.send') + '...');
            const sendData = this._cleanValues($form.form('get values'));
            return Service.updateUserInfo(sendData)
                .then((user) => {
                    let oldUser = Session.getUserData("user-info");
                    Session.setUserData("user-info", user);
                    this._initUserInformation(user);

                    // reload to change language
                    if (user.language && user.language != oldUser.language) {
                        setNewLanguage(user.language);
                    }
                })
                .always(() => {
                    logDimmer.hide();
                });
        } else {
            return $.Deferred().reject();
        }
    }

    isPasswordOK(password1: string, password2: string) {
        return password1 == password2;
    }

    _saveUserLoginInformation() {
        let $form = this.$parent.find('.login-data.form');
        if ($form.form('validate form')) {
            // TODO: speichern text
            let sendData: { password1: string, password2: string } = <any>this._cleanValues($form.form('get values'));
            if (!this.isPasswordOK(sendData.password1, sendData.password2)) {
                return $.Deferred().reject();
            }
            let logDimmer = new Dimmer($form).show(i18next.t('portal.send') + '...');
            return Service.updateUserLogin(sendData)
                .then((token) => {
                    if (token) {
                        Session.setAuthString(Utils.getAuthString(token));
                        Session.setLoggedIn(true);
                        Session.setUserData("user", btoa(sendData['username']));
                        this._initUserLoginInformation();
                    }
                })
                .always(() => {
                    logDimmer.hide();
                });
        } else {
            return $.Deferred().reject();
        }
    }

    _saveClientInformation() {
        const $form = this.$parent.find('.client-data.form');
        if ($form.form('validate form')) {
            const sendData = this._cleanValues($form.form('get values'));

            // prüfe auf Änderungen
            if (!this._hasValueChanges(this.preEditClientData, sendData)) {
                // keine Aktion nötig
                this.preEditClientData = null;
                return $.Deferred().resolve();
            }

            this.preEditClientData = null;
            // speichern text
            const logDimmer = new Dimmer($form).show(i18next.t('portal.send') + '...');

            return Service.updateClientInfo(sendData)
                .then((client) => {
                    Session.setUserData("client", client);
                    this._initClientInformation(client);
                })
                .always(() => {
                    logDimmer.hide();
                });
        } else {
            return $.Deferred().reject();
        }
    }

    dispose() {
        this._unbindEvents();
    }
}

export default SettingsView;
