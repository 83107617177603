import * as Service from './service';

export class Holidays {
    protected static holidaysDefer = null;
    static load() {
        if (!this.holidaysDefer) {
            return (this.holidaysDefer = Service.getRelevantHolidays());
        }

        return this.holidaysDefer;
    }
}
