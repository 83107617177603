import Session from './session';
import * as Service from './service';
//import MainView from './view/main';
import i18next from 'i18next';
import Moment from 'moment';
import {
    Dimmer
} from './spinner';
import { AddWorkDays, ValidateDate } from './utils';

import ShoppingCartDialog from '../templates/shopping_cart.hbs';
import { Holidays } from "./Holidays";


class ShoppingCart {
    protected articleTabActive: boolean;
    protected items: { [x: string]: any; };
    protected userDataId: string;

    constructor() {
        this.articleTabActive = true;
    }

    init(client, user) {
        const userhash = btoa(user.username);
        this.userDataId = `shopping-cart-${userhash || 0}`;

        if (user['native-client-id'] != client.id) {
            this.userDataId += `-${client.id}`;
        }

        // Warenkorb aus localStorage laden
        this._load();

        this.update();

        // Warenkorb anzeigen Event
        $('.shopping-cart.item').on('click', (evt) => {
            Holidays.load()
                .then((holidays) => {
                    this._onShowCart(evt, holidays);
                }, () => {
                    this._onShowCart(evt);
                });
        });
    }

    _load() {
        this.items = Session.getUserData(this.userDataId) || this.items;
        if (!(this.items instanceof Object)) {
            this.items = {};
        }
    }

    _save() {
        Session.setUserData(this.userDataId, this.items);
    }

    addItem(articleNr: string, name?: string, count?: number, unit?: string, id?: number) {
        let tmpItem = this.items[articleNr];
        if (tmpItem) {
            tmpItem.count += (count || 1);
        } else if (name != null) {
            this.items[articleNr] = {
                articleNr,
                name,
                count,
                unit,
                id
            };
        }
        this._save();
        this.update();
    }

    removeItem(id) {
        let tmpItem = this.items[id];
        if (tmpItem && tmpItem.count > 0) {
            tmpItem.count--;
            this._save();
            this.update();
        }
    }

    deleteItem(id) {
        delete this.items[id];
        this._save();
        this.update();
    }

    clear() {
        this.items = {};
        this._save();
        this.update();
    }

    getCount() {
        return Object.keys(this.items).length;
    }

    get(id) {
        return this.items[id];
    }

    getAll() {
        let result = [];
        for (var key in this.items) {
            result.push(this.items[key]);
        }
        return result;
    }

    update() {
        // Warenkorb Icon aktualisieren
        let cartIcon = $('.shopping-cart.item');
        let count = this.getCount();
        cartIcon.toggleClass('hidden', count == 0)
            .find('.counter').text(count);
    }

    _onShowCart(evt, holidays?: any[]) {
        evt.stopPropagation();
        evt.preventDefault();

        Service.logStatisticsEvent('shoppingcart', 'open');

        // show cart dialog
        let $cart = this._createMessageWindow(false);
        this._bindCartEvents($cart);

        $cart.find('[type="date"]').parent().calendar();

        //$cart.find('div.checkbox').checkbox();
        $cart.find('.form').form();

        $cart.find('.menu .item').tab({
            onVisible: (tab) => {
                this.articleTabActive = tab == 'article';
                this._enableActiveTab($cart);
            }
        });

        // setup visible fields
        this._onReceivementChange($cart);

        // get active tab
        const $activeTab = $cart.find('.tabular.menu .item.active');
        this.articleTabActive = $activeTab.attr('data-tab') == 'article';
        this._enableActiveTab($cart);

        const disabledDays = [];
        if (holidays) {
            holidays.forEach(function(elem) {
                disabledDays.push({
                    date: new Date(elem.date),
                    message: (elem.message || '').replace(/(?:\r\n|\r|\n)/g, ' / ')
                });
            });
        }

        $cart.modal({
            closable: false,
            onApprove: () => {
                // check active tab
                if (this.articleTabActive) {
                    this.articleTabActive = false;
                    this._enableActiveTab($cart);
                    return false;
                }

                // prüfe Details Angaben auf Vollständigkeit
                if (!$cart.find('.form').form('validate form')) {
                    this.articleTabActive = false;
                    this._enableActiveTab($cart);
                    return false;
                }

                const cartDetails = $cart.find('.form').form('get values');
                const propertyName = cartDetails.receivement === 'pickup' ? 'pickup' : 'delivery';

                if (!ValidateDate(cartDetails[`${propertyName}-date`], disabledDays)) {
                    $cart.find(`.form .field.${propertyName}`).addClass('error');
                    return false;
                }

                cartDetails.retoure = cartDetails.retoure == 'true';
                cartDetails['unloading-as-always'] = cartDetails['unloading-as-always'] == 'on';
                cartDetails['tel-avis-as-always'] = cartDetails['tel-avis-as-always'] == 'on';

                // prüfe Inhalt des Warenkorbs, zeige warnung an
                const cartItems = this.getAll();
                let count = 0;
                for (var i = 0; i < cartItems.length; i++) {
                    count += cartItems[i].count;
                }
                if (count <= 0) {
                    $cart.find('.warning.message.items').removeClass('hidden');
                    return false;
                } else {
                    $cart.find('.warning.message.items').addClass('hidden');
                }

                Service.logStatisticsEvent('shoppingcart', 'send');

                // send order
                var dimmer = new Dimmer($cart).show(i18next.t('shoppingcart.sending'));
                Service.sendOrder(cartItems, cartDetails)
                    .then((result: boolean) => {
                        if (!result) {
                            return $.Deferred().reject();
                        }
                        dimmer.hide();
                        // reset shopping cart
                        this.clear();
                        $cart.modal('hide');
                    })
                    .fail(() => {
                        // show error
                        dimmer.hide();
                        $cart.find('.negative.message').removeClass('hidden');
                    });
                return false;
            },
            onHidden: () => {
                // remove from body
                $cart.remove();

                const currentView = window.ViewManager.getCurrentView();
                if (currentView["getCurrentViewName"]) {
                    const viewName = (<any>currentView).getCurrentViewName();
                    Service.logStatisticsEvent(viewName, 'open');
                }
            },
            onShow: () => {
                // Abholung nur Sprache DE einblenden
                $cart.find('.details .fields.pickup').toggleClass('hidden', (Session.getLanguage() || '').toUpperCase() !== 'DE');

                const dateInputs = $cart.find('.form [type="date"]');
                // change type to text to disable browser popup
                dateInputs.attr('type', 'text');
                const defaultDates = {
                    "days": ["S", "M", "D", "M", "D", "F", "S"],
                    "months": ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
                    "monthsShort": ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
                    "today": "Heute",
                    "now": "Jetzt",
                    "am": "AM",
                    "pm": "PM"
                };

                const client = Session.getUserData('client');
                let offsetDays = 2;
                // additional +3 day delivery delay time for not Austria & Liechtenstein clients
                if (!client || !client.country || (client.country.toLowerCase() !== 'ch' && client.country.toLowerCase() !== 'li')) {
                    offsetDays += 3;
                }

                dateInputs.parent().calendar({
                    type: 'date',
                    minDate: AddWorkDays(new Date(), offsetDays, disabledDays),
                    disabledDaysOfWeek: [0, 6],
                    disabledDates: disabledDays,
                    firstDayOfWeek: 1,
                    formatter: {
                        date: function(date: Date) {
                            return Moment(date).format('L');
                        }
                    },
                    parser: {
                        date: function(text: string) {
                            const dt = Moment(text, 'L');
                            return dt.isValid() ? dt.toDate() : null;
                        }
                    },
                    text: i18next.t('calender', { returnObjects: true, defaultValue: defaultDates })
                });
            },
            onVisible: () => {
                $cart.find('i.info').popup({
                    position: 'top center'
                });
            }
        })
            .modal('refresh')
            .modal('show');
    }

    _onReceivementChange($cart) {
        const $selection = $cart.find('[data-tab="details"] input[name="receivement"]:checked');
        if ($selection.parent().hasClass('pickup')) {
            $cart.find('.field.pickup').removeClass('hidden');
            $cart.find('.field.delivery, .fields.delivery').addClass('hidden');
            $cart.find('.form').form({
                fields: {
                    'pickup-date': ['empty'],
                    'pickup-time': ['empty']
                }
            });
            $cart.find('.message.pickup').removeClass('hidden');
            $cart.find('.message.delivery').addClass('hidden');
        } else {
            $cart.find('.field.delivery, .fields.delivery').removeClass('hidden');
            $cart.find('.field.pickup').addClass('hidden');
            $cart.find('.form').form({
                fields: {
                    'delivery-date': ['empty']
                }
            });
            $cart.find('.message.pickup').addClass('hidden');
            $cart.find('.message.delivery').removeClass('hidden');
        }
    }

    _enableActiveTab($cart) {
        $cart.find('.menu .item').tab('change tab', this.articleTabActive ? 'article' : 'details');
        $cart.find('.approve.button').text(this.articleTabActive ? i18next.t('shoppingcart.continue') : i18next.t('shoppingcart.send-order'));
    }

    _createMessageWindow(showPrompt: boolean) {
        let data = this.getAll();
        let $cart = $(ShoppingCartDialog(data));
        $('body').append($cart);
        $cart.localize();
        return $cart;
    }

    _bindCartEvents($cart) {
        const self = this;

        $cart.on('change', 'input[name="receivement"]', function() {
            self._onReceivementChange($cart);
        });

        $cart.on('click', '.button.subtract', function(evt: Event) {
            // remove 1 piece
            let id = $(evt.target).closest('.row').attr('data-article');
            let $input = $(evt.target).closest('.grid').find('input');
            let tmpVal = $input.val();
            if (tmpVal > 0) {
                tmpVal--;
                self.removeItem(id);
                $input.val(tmpVal);
            }

            $input.transition('pulse', '100ms');
        });

        $cart.on('click', '.button.add', function(evt: Event) {
            // add 1 piece
            let articleNr = $(evt.target).closest('.row').attr('data-article');
            let $input = $(evt.target).closest('.grid').find('input');
            let tmpVal = $input.val();
            tmpVal++;
            self.addItem(articleNr);
            $input.val(tmpVal);

            $input.transition('pulse', '100ms');
        });

        $cart.on('change', 'input.amount', function(evt) {
            let id = $(evt.target).closest('.row').attr('data-article');
            let tmpVal = +evt.target.value;
            if (tmpVal < 0) {
                tmpVal = 0;
                evt.target.value = 0;
            }
            let item = self.get(id);
            if (item) {
                item.count = tmpVal;
                self._save();
                self.update();
            }
        });

        $cart.on('click', '.button.delete', function(evt: Event) {
            // remove item from cart
            let $row = $(evt.target).closest('.row');
            let id = $row.attr('data-article');
            $row.transition({
                animation: 'fade right',
                onComplete: () => {
                    self.deleteItem(id);
                    $row.remove();
                }
            });
        });

        $cart.find('.ui.checkbox.tel-avis').checkbox({
            'onChange': function() {
                $cart.find('input[name="tel-avis"]').prop('disabled', $(this).is(':checked'));
            }
        });

        $cart.find('.ui.checkbox.unloading').checkbox({
            'onChange': function() {
                $cart.find('input[name="unloading-note"]').prop('disabled', $(this).is(':checked'));
            }
        });
    }
}

const ShoppingCartInstance = new ShoppingCart();
export default ShoppingCartInstance;
