import Helpers from './helper/handlebars';
import ViewManager from './view/view_manager';
import Polyfill from './polyfill';
import '../less/main.less';
import { getCurrentLanguage } from './utils';
import PageSpinner from './spinner';
import { TranslateErrors } from './errors';
import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';
import j18n from 'jquery-i18next';
import Moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';

const VERSION = '21.1.28';

PageSpinner.show();

// Handlebars Helpers registrieren
Helpers();

// Polyfills erstellen
Polyfill();

// DOM Ready Event binden
$(document).ready(onReady);

function onReady() {
    i18next.use(XHR)
        .init({
            lng: getCurrentLanguage(),
            fallbackLng: 'de',
            debug: true,
            backend: {
                // load from i18next-gitbook repo
                loadPath: 'locales/{{lng}}.json',
                crossDomain: false
            }
        }, function(err, t) {
            // Sprache definieren
            Moment.locale(i18next.language);

            // jquery i18next initialisieren
            j18n.init(i18next, $);

            // Fehlermeldungen übersetzen
            TranslateErrors();

            PageSpinner.hide();

            // App starten
            window.ViewManager = new ViewManager();

            // Title der Seite separat setzen
            $("title").text(i18next.t('customer-portal'));
            $(".page-title").localize();
            $(".footer").localize();

            // file drop ausserhalb des vorgesehenen bereichs vermeiden
            $('body')
                .on('dragover', (e: Event) => {
                    e.preventDefault();
                    e.stopPropagation();
                })
                .on('dragenter', (e: Event) => {
                    e.preventDefault();
                    e.stopPropagation();
                })
                .on('drop', (e: Event) => {
                    e.preventDefault();
                    e.stopPropagation();
                });
        });
}
