import SubView from './sub_view';
import * as Service from '../service';
import DownloadHelper from '../download_helper';
import {
    Dimmer
} from '../spinner';
import Pager from '../pager';
import Message from '../message';
import i18next from 'i18next';
import Handlebars from 'handlebars/runtime';

import EmptyPreviewItem from '../../templates/empty_preview_item.hbs';
import UserFileItem from '../../templates/userfile_item.hbs';
import UploadDialog from '../../templates/upload_dialog.hbs';
import UserFileDetailDialog from '../../templates/userfile_detail_dialog.hbs';

const docsPerPage = 10;

class FileExchangeView extends SubView {
    protected searchFilter: any;
    protected currentOffset: number;
    protected lastRequestParameters: { offset: number, searchFilter?: string };

    constructor(container: any) {
        super(container);
    }

    init() {
        this.searchFilter = null;
        this.lastRequestParameters = {
            offset: -1
        };

        this.$parent.pager = new Pager(this.$parent.find('.menu.pagination'));
        this.$parent.pager.setPage(0);
        this._bindEvents();
        this.$parent.find('.file-listing').css('max-height', 1000);
    }

    _bindEvents() {
        this.$parent.on('click', '.upload.button', $.proxy(this._onFileUpload, this));
        this.$parent.on('click', '.download.button', $.proxy(this._onUserFileDownload, this));
        this.$parent.on('click', 'table tr', $.proxy(this._onFileClick, this));
        this.$parent.find('.button.search').on('click', $.proxy(this._onFilterFiles, this));
        this.$parent.find('.search input').on('keydown', (evt) => {
            if (evt.keyCode != 13) {
                return;
            }
            this._onFilterFiles(evt);
            evt.preventDefault();
        });

        this.$parent.pager.element.on('change', (evt: Event, page: number, total: number) => {
            this._loadUserFiles(page * docsPerPage);
        });
    }

    _unbindEvents() {
        this.$parent.off('click');
        this.$parent.find('.button.search').off('click');
        this.$parent.find('.search input').off('keydown');
    }

    _onShown() {
        this._loadUserFiles(0);
    }

    _loadUserFiles(offset?: number, forceReload: boolean = false) {
        // just for the case, offset has not been set
        offset = offset || 0;

        if (this.lastRequestParameters.offset == offset && !forceReload &&
            this.lastRequestParameters.searchFilter == this.searchFilter) {
            // no need for update
            return;
        }

        this.lastRequestParameters.offset = offset;
        this.lastRequestParameters.searchFilter = this.searchFilter;

        var self = this;
        var $docs = this.$parent;
        var docsDimmer = new Dimmer($docs).show(i18next.t('portal.loading'));
        Service.getUserFiles(offset, docsPerPage, this.searchFilter)
            .fail(function(msg) {
                docsDimmer.hide();
                Message.show(i18next.t('portal.error'), msg.statusText + ':<br>' + msg.responseText);
            })
            .then(function(data, textStatus, response) {
                self.currentOffset = offset;
                var totalCount = response.getResponseHeader('X-Documents-Count');
                var fromToRange = response.getResponseHeader('X-Documents-Range');
                $docs.pager.setPageCount(Math.ceil(totalCount * 1 / docsPerPage));

                var $list = $docs.find('.userfiles-list').empty();
                if (!data || !data.length) {
                    $list.append(EmptyPreviewItem());
                } else {
                    for (var i = 0; i < data.length; i++) {
                        $list.append(UserFileItem(data[i]));
                    }
                }
                $list.localize();
                docsDimmer.hide();
            });
    }

    _onFileClick(evt: Event) {
        const self = this;
        const fileID = $(evt.currentTarget).closest('tr.item').data('uid');
        // load all files
        Service.getUserFileVersion(fileID)
            .then((result: { current: any, versions: any }) => {
                const $dialog = $(UserFileDetailDialog(result));
                $dialog.localize();
                $('.modal-container').append($dialog);

                $dialog.modal({
                    autofocus: false,
                    closable: true,
                    allowMultiple: true,
                    onHidden: function() {
                        $dialog.modal('destroy');
                        $dialog.remove();
                    },
                    onVisible: function() { },
                    onShow: function() {
                        $dialog.on('click', '.download.button', $.proxy(self._onUserFileDownload, self));
                    },
                    onApprove: function(element) {
                        if (element.hasClass('delete')) {
                            // Sicherheitsabfrage zum löschen der Datei
                            Message.prompt(
                                i18next.t('upload.deletion.prompt-title'),
                                i18next.t('upload.deletion.prompt-message', { file: result.current.filename }),
                                () => {
                                    Service.deleteUserFile(fileID)
                                        .then((success: boolean) => {
                                            if (success) {
                                                $dialog.modal('hide');
                                                $('body .dimmer').dimmer('hide');
                                                self._loadUserFiles(0, true);
                                            }
                                        })
                                        .fail(() => {
                                            Message.show(
                                                i18next.t('upload.deletion.error-title'),
                                                i18next.t('upload.deletion.error-title')
                                            );
                                        });
                                }, $.noop);
                            return false;
                        }
                    },
                    onDeny: function(element) { }
                }).modal('show');
            })
    }

    _onFileUpload(evt) {
        const $dialog = $(UploadDialog());
        $dialog.localize();

        $('.modal-container').append($dialog);

        const self = this;
        let uploadFile: File = null;

        const handleFileSelection = function(files: File[]) {
            for (let i = 0; i < files.length; i++) {
                const curfile: File = files[i];
                if (!curfile.type) {
                    continue;
                }
                // set as current upload file
                uploadFile = curfile;

                const fileType = Handlebars.helpers.document_icon(curfile.name);
                const fileSize = Handlebars.helpers.file_size(curfile.size);
                const fileDate = Handlebars.helpers.date_format(curfile.lastModified || new Date());

                // set values
                $dialog.find('.description .icon').addClass(fileType);
                $dialog.find('.description .filename').text(curfile.name);
                $dialog.find('.description .filesize').text(fileSize);
                $dialog.find('.description .date').text(fileDate);

                $dialog.find('.positive').removeClass('disabled');
                $dialog.find('.dropzone').addClass('hidden');
                $dialog.find('.description').removeClass('hidden');
                $dialog.find('.description textarea').focus();

                // TODO (OPTIONAL) list history versions of this file

                break; // for loop
            }
        };

        $dialog.modal({
            autofocus: false,
            closable: false,
            allowMultiple: true,
            dimmerSettings: {
                variation: 'inverted'
            },
            onHidden: function() {
                $dialog.modal('destroy');
                $dialog.remove();
            },
            onVisible: function() {
            },
            onShow: function() {
                $dialog.find('input[type="file"]').on('change', function() {
                    // manuelles wählen der Dateien
                    handleFileSelection(this.files);
                });

                let blockLeave = false;

                $dialog.find('.dropzone')
                    .on('dragover', function(e) {
                        e.preventDefault();
                        e.stopPropagation();
                    })
                    .on('dragleave', function(e) {
                        if (blockLeave) {
                            blockLeave = false;
                        } else {
                            e.currentTarget.style.backgroundColor = ''
                        }

                        e.preventDefault();
                        e.stopPropagation();
                    })
                    .on('dragenter', function(e) {
                        if ($(e.target).closest(e.currentTarget).length > 0) {
                            blockLeave = true;
                        }

                        e.currentTarget.style.backgroundColor = '#eea'
                        e.preventDefault();
                        e.stopPropagation();
                    })
                    .on('drop', function(e) {
                        e.currentTarget.style.backgroundColor = ''
                        e.stopPropagation();
                        e.preventDefault();

                        // only 1 file support
                        if (e.originalEvent.dataTransfer.files.length) {
                            handleFileSelection(e.originalEvent.dataTransfer.files);
                        }
                    });
            },
            onApprove: function(element) {
                const comment = $dialog.find('.description textarea').val();

                // TODO lock area, show progress
                if (uploadFile) {
                    $dialog.find('.progress').removeClass('hidden');
                    $dialog.find('.dimmer').addClass('active');
                    $dialog.find('.negative.message').addClass('hidden');

                    Service.uploadFile(uploadFile, comment)
                        .progress((x) => {
                            console.log('progress: ', x);
                        })
                        .then(() => {
                            $dialog.modal('hide');
                            // update list
                            self.lastRequestParameters.offset = -1;
                            self._loadUserFiles(0);
                        })
                        .fail((msg) => {
                            $dialog.find('.progress').addClass('hidden');
                            $dialog.find('.dimmer').removeClass('active');
                            $dialog.find('.negative.message').removeClass('hidden')
                                .find('p').text(msg.statusText);
                        });
                }

                return false;
            },
            onDeny: function(element) {
                uploadFile = null;
            }
        }).modal('show');
    }

    _onUserFileDownload(evt: Event) {
        evt.stopPropagation();
        evt.preventDefault();

        const uid = $(evt.currentTarget).data('uid');
        DownloadHelper.downloadFile(uid);
    }

    _onFilterFiles(evt: Event) {
        this.searchFilter = $(evt.currentTarget).parent().find('input').val().toLowerCase();
        this._loadUserFiles(0);
    }

    dispose() {
        this._unbindEvents();
    }
}

export default FileExchangeView;
