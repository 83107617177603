<div class="ui cart message modal">
    <div class="header"><i class="shopping cart icon"></i><span data-i18n="shoppingcart.title">Warenkorb</span></div>
    <div class="ui top attached tabular menu">
        <div class="active item" data-tab="article" data-i18n="shoppingcart.article">Artikel</div>
        <div class="item" data-tab="details" data-i18n="shoppingcart.details">Details</div>
    </div>
    <div class="ui bottom attached active tab segment" data-tab="article">
        <div class="content">
            <div class="ui center aligned grid">
                <div class="row">
                    <div class="three wide column header" data-i18n="shoppingcart.article-nr">
                        Artikel-Nr
                    </div>
                    <div class="seven wide column header" data-i18n="shoppingcart.description">
                        Bezeichnung
                    </div>
                    <div class="three wide column header" data-i18n="shoppingcart.quantity">
                        Menge
                    </div>
                    <div class="one wide column">
                    </div>
                    <div class="two wide column">
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="scrolling content" style="max-height: 50%">
            <div class="ui middle aligned items grid">
                {{#each .}}
                    <div class="row" data-id="{{id}}" data-article="{{articleNr}}">
                        <div class="right aligned three wide column">
                            {{articleNr}}
                        </div>
                        <div class="seven wide column">
                            {{name}}
                        </div>
                        <div class="three wide column">
                            <div class="ui middle aligned grid actions">
                                <div class="right aligned four wide column">
                                    <div class="ui small basic circular subtract icon button">
                                        <i class="ui minus icon"></i>
                                    </div>
                                </div>
                                <div class="center aligned eight wide column">
                                    <div class="ui fluid input">
                                        <input class="amount" type="number" value="{{count}}" />
                                    </div>
                                </div>
                                <div class="left aligned four wide column">
                                    <div class="ui small basic circular add icon button">
                                        <i class="ui plus icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="left aligned one wide column">
                            {{unit}}
                        </div>
                        <div class="two wide center aligned column">
                            <div class="ui basic circular delete icon button" title="Artikel aus Warenkorb entfernen" data-i18n="[title]shoppingcart.remove-cart">
                                <i class="ui remove icon"></i>
                            </div>
                        </div>
                    </div>
                {{/each}}
            </div>
        </div>
    </div>
    <div class="ui bottom attached tab segment" data-tab="details">
        <div class="ui form details">
            <div class="inline fields pickup">
                <div class="field">
                    <div class="ui radio checkbox delivery">
                        <input type="radio" name="receivement" value="delivery" checked="checked" id="receivement-delivery">
                        <label for="receivement-delivery" data-i18n="shoppingcart.delivery.title">Lieferung</label>
                    </div>
                </div>
                <div class="field">
                    <div class="ui radio checkbox pickup">
                        <input type="radio" name="receivement" value="pickup" id="receivement-pickup">
                        <label for="receivement-pickup" data-i18n="shoppingcart.pickup.title">Abholung</label>
                    </div>
                </div>
            </div>
            <div class="two fields">
                <div class="eight wide field">
                    <label data-i18n="shoppingcart.order-id">Bestell-Nr:</label>
                    <div class="ui icon input">
                        <input type="text" name="order-id" />
                        <i class="inverted circular info link icon" data-i18n="[title]shoppingcart.order-id-info"></i>
                    </div>
                </div>
                <div class="eight wide required field delivery">
                    <label data-i18n="shoppingcart.delivery-date">Liefertermin:</label>
                    <input type="date" name="delivery-date" />
                </div>
                <div class="four wide required field pickup">
                    <label data-i18n="shoppingcart.pickup-date">Abhol-Datum:</label>
                    <input type="date" name="pickup-date" />
                </div>
                <div class="four wide required field pickup">
                    <label data-i18n="shoppingcart.pickup-time">Abhol-Zeit:</label>
                    <input type="time" name="pickup-time" />
                </div>
            </div>
            <div class="two fields delivery">
                <div class="field">
                    <label data-i18n="shoppingcart.tel-avis">Tel-Avis:</label>
                    <input type="text" name="tel-avis" />
                    <div class="ui checkbox tel-avis">
                        <input name="tel-avis-as-always" type="checkbox">
                        <label data-i18n="shoppingcart.as-always">
                            wie gehabt
                        </label>
                    </div>
                </div>
                <div class="field">
                    <label data-i18n="shoppingcart.unloading-note">Bemerkung für Ablad:</label>
                    <div class="ui icon input">
                        <input type="text" name="unloading-note" />
                        <i class="inverted circular info link icon" data-i18n="[title]shoppingcart.unloading-info"></i>
                    </div>
                    <div class="ui checkbox unloading">
                        <input name="unloading-as-always" type="checkbox">
                        <label data-i18n="shoppingcart.as-always">
                            wie gehabt
                        </label>
                    </div>
                </div>
            </div>
            <div class="inline fields">
                <label for="retoure" data-i18n="shoppingcart.retoure">Retouren Leergebinde?</label>
                <div class="field">
                    <div class="ui radio checkbox">
                        <input type="radio" name="retoure" checked="true" class="hidden" value="true" id="retoure-yes">
                        <label for="retoure-yes" data-i18n="shoppingcart.yes">ja</label>
                    </div>
                    <div class="ui radio checkbox">
                        <input type="radio" name="retoure" class="hidden" value="false" id="retoure-no">
                        <label for="retoure-no" data-i18n="shoppingcart.no">nein</label>
                    </div>
                </div>
            </div>
            <div class="field">
                <label data-i18n="shoppingcart.your-message">Ihre Nachricht</label>
                <textarea name="customer-message" rows="3" data-i18n="[placeholder]shoppingcart.your-message-info"></textarea>
            </div>
        </div>
    </div>
    <div class="">
        <div class="ui warning message delivery">
            <p data-i18n="shoppingcart.delivery.conditions-1">
                Bitte beachten Sie Ihre Lieferkonditionen sowie Anforderungen seitens Auftragsabwicklung.
            </p>
            <p data-i18n="shoppingcart.delivery.conditions-2">
                Ihre Bestellung muss von einer Person vor Ort bei der Lieferung angenommen werden.
            </p>
        </div>
        <div class="ui warning message pickup">
            <p data-i18n="shoppingcart.pickup.conditions-1">
                Wir bitten um schriftliche Bestellung mind. 24 Stunden vor Abholung.
            </p>
            <p data-i18n="[html]shoppingcart.pickup.conditions-2">
                Zahlung nur via Rechnung möglich. <strong>Vorschrift: Feuerlöscher auf dem Fahrzeug!</strong>
            </p>
        </div>
        <div class="ui negative message pickup">
            <p data-i18n="[html]shoppingcart.pickup.warning">
                Feuerlöscher!
            </p>
        </div>
        <div class="ui negative icon message hidden">
            <i class="ui exclamation icon"></i>
            <div class="content">
                <div class="header" data-i18n="shoppingcart.error-send">
                    Fehler beim Senden der Bestellung aufgetreten!
                </div>
            </div>
        </div>
        <div class="ui warning icon message items hidden">
            <i class="ui warning circle icon"></i>
            <div class="content">
                <div class="header" data-i18n="shoppingcart.empty-warning">
                    Ihr Warenkorb ist leer!
                </div>
                <p data-i18n="shoppingcart.check-quantity">Prüfen Sie evtl. die Menge.</p>
            </div>
        </div>
    </div>
    <div class="actions">
        <div class="ui cancel button" data-i18n="shoppingcart.cancel">
            Abbrechen
        </div>
        <div class="ui green approve button" data-i18n="shoppingcart.send-order">
            Bestellen
            <i class="right chevron icon"></i>
        </div>
    </div>
</div>
