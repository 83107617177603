<div class="item" data-id="{{id}}">
    {{!--
    {{#if datasheets}}
        <div class="right floated middle aligned content" style="margin-bottom: -35px;">
            <!--
            <div class="ui basic circular icon in cart button"><i class="Shopping Basket icon"></i></div>
            -->
            <div class="ui basic circular icon download button"><i class="download icon"></i></div>
        </div>
    {{/if}}
    --}}
    <i class="large halag-red-text cube middle aligned icon"></i>
    <div class="content">
        <div class="header" title="{{name}}">{{{short_string name 28}}}</div>
        <div class="description">{{date_format purchase-date}}</div>
    </div>
</div>
