/*
* Grundklasse für eine View mit erforderlichen Funktionen
*/
class SubView {
    protected $parent;

    constructor(container) {
        this.$parent = container;
    }

    init() {
        throw new TypeError(`'${this.constructor.name}' class must implement method 'init()'`);
    }

    show() {
        return this._flipIn(this.$parent);
    }

    hide() {
        return this._flipOut(this.$parent);
    }

    dispose() {
        throw new TypeError(`'${this.constructor.name}' class must implement method 'dispose()'`);
    }

    _onShown() {
    }

    _onHidden() {
    }

    _flipIn(element) {
        const self = this;
        const flipDefer = $.Deferred();
        element.transition({
            animation: 'vertical flip in',
            onComplete: function() {
                $(this).addClass('active').remove('hidden');
                self._onShown();
                flipDefer.resolve(this);
            }
        });
        return flipDefer.promise();
    }

    _flipOut(element) {
        const self = this;
        const flipDefer = $.Deferred();
        element.transition({
            animation: 'vertical flip out',
            onComplete: function() {
                $(this).removeClass('active');
                self._onHidden();
                flipDefer.resolve(this);
            }
        });
        return flipDefer.promise();
    }
}

export default SubView;
