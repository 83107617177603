import View from './view';
import PageSpinner, { Dimmer } from '../spinner';
import * as Service from '../service';
import * as Utils from '../utils';﻿
import i18next from 'i18next';

class RecoverView extends View {
    protected $parent;
    protected token;

    constructor(userName) {
        super();
    }

    init() {
        this.show();
    }

    show() {
        PageSpinner.show('');
        this.$parent = $('.main-content');
        this.$parent.load('templates/recover.html', (evt) => {
            $(this.$parent).localize();

            // Check for Reset Token
            const token = this._getToken();
            if(token) {
                this.token = token;
                this.$parent.find('.reset.row').removeClass('hidden');
                this.$parent.find('.request.row').addClass('hidden');
            }

            this._initValidation();
            this.$parent.find('.request-box button').on("click", $.proxy(this.sendRecoverRequest, this));
            this.$parent.find('.reset-box button').on("click", $.proxy(this.sendResetRequest, this));

            this.$parent.find('input#username').on('keydown', $.proxy(this._triggerEnter, this));
            this.$parent.find('input#password').on('keydown', $.proxy(this._triggerEnter, this));
            this.$parent.find('input#password-confirm').on('keydown', $.proxy(this._triggerEnter, this));

            PageSpinner.hide();
        });
    }

    _getToken() {
        let param = Utils.getParameterFromUrl('t');
        if(param!=null) {
            return param;
        }
        return null;
    }

    _initValidation() {
        this.$parent.find('.form.reset-box')
            .form({
                on: 'blur',
                fields: {
                    'username': ['email', 'empty'],
                    'password': ['minLength[6]','empty'],
                    'password-confirm': 'match[password]'
                }
            })
        ;

        this.$parent.find('.form.request-box')
            .form({
                on: 'blur',
                fields: {
                    'username': ['email', 'empty']
                }
            })
        ;
    }

    _triggerEnter(evt) {
        if (evt.keyCode != 13) {
            return;
        }
        const nextField = $(evt.currentTarget).closest('.field').next('.field');
        if(nextField.length>0){
            nextField.find('input').focus();
        }else{
            $(evt.currentTarget).closest('.form').find('button').click();
        }
        evt.preventDefault();
    }

    sendRecoverRequest(evt) {
        const self = this;
        if (!this.$parent.find('.form.request-box').form('validate form')) {
            return;
        }
        const logDimmer = new Dimmer($('.request-box')).show(i18next.t('portal.recover.send-request'));
        const loginValues = $('.request-box.form').form('get values');
        Service.requestPasswordReset(loginValues.username)
            .then(function(data) {
                if (!data) {
                    return $.Deferred().reject().promise();
                }

                self.$parent.find('.request.row').addClass('hidden');
                self.$parent.find('.check-info.row').removeClass('hidden');
            })
            .fail(function(msg) {
                self.$parent.find('.form.request-box').addClass('error');
                self.$parent.find('.form.request-box .message').text(msg ? msg.statusText : i18next.t('portal.unexpected-error-occured'));
            })
            .always(() => {
                logDimmer.hide();
            });
    }

    sendResetRequest(evt: Event) {
        const self = this;
        if (!this.$parent.find('.form.reset-box').form('validate form')) {
            return;
        }
        const logDimmer = new Dimmer($('.reset-box')).show(i18next.t('portal.recover.send-request'));
        const loginValues = $('.reset-box.form').form('get values');
        Service.resetPasswordReset(loginValues.username, loginValues.password, this.token)
            .then(function(data) {
                if (!data) {
                    return $.Deferred().reject().promise();
                }

                self.$parent.find('.reset.row').addClass('hidden');
                self.$parent.find('.success-info.row').removeClass('hidden');
            })
            .fail(function(msg) {
                self.$parent.find('.form.reset-box').addClass('error');
                self.$parent.find('.form.reset-box .message').text(msg ? msg.responseText : i18next.t('portal.unexpected-error-occured'));
            })
            .always(() => {
                logDimmer.hide();
            });
    }

    dispose() {

    }
}

export default RecoverView;
