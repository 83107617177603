<div class="item">
    <div class="right floated middle aligned content" style="margin-bottom: -35px;">
        <a href="{{url}}" target="_blank">
            <div class="ui basic circular icon download button"><i class="download icon"></i></div>
        </a>
    </div>
    {{#if isnew}}
    <span class="notify-badge light" data-i18n="portal.docs.new">NEW</span>
    {{/if}}
    <i class="large halag-red-text {{document_icon name}} file outline middle aligned icon"></i>
    <div class="content">
        <div class="header" title="{{name}}">{{{short_string name 30}}}</div>
        <div class="description">{{date_format lstchg}}</div>
    </div>
</div>
