import SubView from './sub_view';
import * as Service from '../service';
import DownloadHelper from '../download_helper';
import Message from '../message';
import {
    Dimmer
} from '../spinner';
import Pager from '../pager';
import i18next from 'i18next';

import EmptyPreviewItem from '../../templates/empty_preview_item.hbs';
import DocumentItem from '../../templates/document_item.hbs';

const docsPerPage = 10;

class LabReportsView extends SubView {
    protected searchFilter: any;
    protected docTypeFilter: any;
    protected currentOffset: number;
    protected lastRequestParameters: { offset: number, docTypeFilter?: any, searchFilter?: any };

    constructor(container: any) {
        super(container);
    }

    init() {
        this.searchFilter = null;
        this.lastRequestParameters = {
            offset: -1
        };

        this.$parent.pager = new Pager(this.$parent.find('.menu.pagination'));
        this.$parent.pager.setPage(0);
        this._bindEvents();
        const newHeight = $('body').height() - 400;
        this.$parent.find('.file-listing').css('max-height', 1000);
    }

    _bindEvents() {
        this.$parent.on('click', '.download.button', $.proxy(this._onDocumentDownload, this));
        this.$parent.find('.button.search').on('click', $.proxy(this._onFilterDocuments, this));
        this.$parent.find('.search input').on('keydown', (evt) => {
            if (evt.keyCode != 13) {
                return;
            }
            this._onFilterDocuments(evt);
            evt.preventDefault();
        });

        this.$parent.pager.element.on('change', (evt, page, total) => {
            this._loadDocuments(page * docsPerPage);
        });
    }

    _unbindEvents() {
        this.$parent.off('click');
        this.$parent.find('.button.search').off('click');
        this.$parent.find('.search input').off('keydown');
    }

    _onShown() {
        this._loadDocuments(0);
    }

    _loadDocuments(offset) {
        // just for the case, offset has not been set
        offset = offset || 0;

        if (this.lastRequestParameters.offset == offset &&
            this.lastRequestParameters.searchFilter == this.searchFilter) {
            // no need for update
            return;
        }

        this.lastRequestParameters.offset = offset;
        this.lastRequestParameters.searchFilter = this.searchFilter;

        const self = this;
        const $docs = this.$parent;
        const docsDimmer = new Dimmer($docs).show(i18next.t('portal.loading'));
        Service.getLabReports(offset, docsPerPage, this.searchFilter)
            .fail(function(msg) {
                docsDimmer.hide();
                Message.show(i18next.t('portal.error'), msg.statusText + ':<br>' + msg.responseText);
            })
            .then(function(data, textStatus, response) {
                self.currentOffset = offset;
                const totalCount = response.getResponseHeader('X-Documents-Count');
                const fromToRange = response.getResponseHeader('X-Documents-Range');
                $docs.pager.setPageCount(Math.ceil(totalCount * 1 / docsPerPage));

                const $list = $docs.find('.lab-reports-list').empty();
                if (!data || !data.length) {
                    $list.append(EmptyPreviewItem());
                } else {
                    for (let i = 0; i < data.length; i++) {
                        $list.append(DocumentItem(data[i]));
                    }
                }
                $list.localize();
                docsDimmer.hide();
            });
    }

    _onDocumentDownload(evt) {
        const uid = $(evt.currentTarget).closest('.item').data('uid');
        DownloadHelper.downloadDocument(uid);
    }

    _onFilterDocuments(evt) {
        this.searchFilter = $(evt.currentTarget).parent().find('input').val().toLowerCase();
        this._loadDocuments(0);
    }

    dispose() {
        this._unbindEvents();
    }
}

export default LabReportsView;
