﻿/*
 * Requires jQuery.
 *
 * Events will be triggered at the pager element.
 * A 'change' event will be triggered.
 */

class Pager {
    protected element;
    protected _pageCount: number;
    protected _currentPage: number;
    protected _maxPageBlockCount: number;

    constructor(element, pageCount?: number) {
        this.element = $(element);
        this._pageCount = pageCount || 0;
        this._setClasses();
        this._setupPagination();
        this._bindEvents();
    }

    setPage(page) {
        this._currentPage = page;
        this._createPager();
        this.element.trigger('change', [this._currentPage, this._pageCount]);
    }

    setPageCount(count) {
        if (this._pageCount == count) {
            return;
        }
        this._pageCount = count || 0;
        this.update();
        if (this._currentPage > this._pageCount) {
            this.setPage(this._pageCount);
        }
    }

    getPage() {
        return this._currentPage;
    }

    getPageCount() {
        return this._pageCount;
    }

    update() {
        this._setupPagination();
        this._createPager();
    }

    _setClasses() {
        this.element
            .addClass('ui')
            .addClass('menu');
    }

    _bindEvents() {
        const self = this;

        this.element.off()
            .on('click.pager', 'a.item', (evt) => {
                const page = parseInt($(evt.target).attr('data-nr'));
                self.setPage(page);
            });

        $(window).off('resize.pager')
            .on('resize.pager', (evt) => {
                self._setupPagination(self._currentPage);
                self._createPager();
            });
    }

    _setupPagination(currentPage?: number) {
        this._currentPage = currentPage || 0;
        this._maxPageBlockCount = -1;

        if (this._pageCount <= 1) {
            this.element.addClass('hidden');
        } else {
            // size test
            this.element
                .removeClass('hidden')
                .empty()
                .append(`<a class="item">${1}</a>`)
                .append(`<a class="item">${Math.round(this._pageCount / 2 + 1)}</a>`)
                .append(`<a class="item">${this._pageCount + 1}</a>`);

            const widthPerBlock = this.element.width() / 3;
            const maxWidth = this.element.parent().width();
            this._maxPageBlockCount = Math.floor(maxWidth / widthPerBlock);

            // minimum 7 buttons for switching between more than 7 pages required
            if (this._pageCount >= 7 && this._maxPageBlockCount < 7) {
                this._maxPageBlockCount = 7;
            }
        }
    }

    _createPager() {
        if (this._pageCount <= 1) {
            // no pager required
            this.element.addClass('hidden');
        } else {
            let pager = this.element.removeClass('hidden').empty();

            if (this._maxPageBlockCount < 0 || this._maxPageBlockCount >= this._pageCount) {
                // show all page buttons
                for (let i = 0; i < this._pageCount; i++) {
                    pager.append(`<a class="item" data-nr="${i}">${i + 1}</a>`);
                }
            } else {
                const offset = Math.floor((this._maxPageBlockCount - 5) / 2);

                if (this._currentPage + offset < this._maxPageBlockCount - 2) {
                    // pre-last item as disabled [...]
                    for (let i = 0; i < this._maxPageBlockCount - 2; i++) {
                        pager.append(`<a class="item" data-nr="${i}">${i + 1}</a>`);
                    }
                    pager.append('<div class="disabled item">...</div>');
                    pager.append(`<a class="item" data-nr="${this._pageCount - 1}">${this._pageCount}</a>`);
                }
                else if ((this._pageCount + offset - this._currentPage) <= this._maxPageBlockCount - 2) {
                    // second item as disabled [...]
                    pager.append(`<a class="item" data-nr="${0}">${1}</a>`);
                    pager.append('<div class="disabled item">...</div>');
                    let startIndex = this._pageCount - (this._maxPageBlockCount - 2);
                    for (let i = startIndex; i < this._pageCount; i++) {
                        pager.append(`<a class="item" data-nr="${i}">${i + 1}</a>`);
                    }
                } else {
                    // second and pre-last item as disabled [...]
                    pager.append(`<a class="item" data-nr="${0}">${1}</a>`);
                    pager.append('<div class="disabled item">...</div>');
                    let startIndex = this._currentPage - offset;
                    let endIndex = startIndex + this._maxPageBlockCount - 4;
                    for (let i = startIndex; i < endIndex; i++) {
                        pager.append(`<a class="item" data-nr="${i}">${i + 1}</a>`);
                    }
                    pager.append('<div class="disabled item">...</div>');
                    pager.append(`<a class="item" data-nr="${this._pageCount - 1}">${this._pageCount}</a>`);
                }
            }
            pager.find(`[data-nr="${this._currentPage}"]`).addClass('active');
        }
    }
}

export default Pager;
