<tr class="item" data-uid="{{uid}}">
    <td>
        {{#if isnew}}
        <span class="notify-badge" data-i18n="portal.docs.new">NEW</span>
        {{/if}}
        <i class="big halag-red-text {{document_icon name}} file outline icon"></i>
    </td>
    <td>
        <h4 class="ui image header">
          <div class="content">
            {{name}}
            <div class="sub header last_change"><span data-i18n="portal.docs.last-change">Letzte Änderung</span>: {{date_format lstchg}}</div>

        </div>
      </h4>
    </td>
    <td>
        {{file_size size}}
    </td>
    <td>
        {{resolve_doctype doctype}}
    </td>
    <td>
        {{date_format docdate}}
    </td>
    <td>
        <div class="ui basic circular icon download button" title="Datei herunterladen" data-i18n="[title]portal.docs.download-file"><i class="download icon"></i></div>
    </td>
</tr>
