import SubView from './sub_view';
import * as Service from '../service';
import DownloadHelper from '../download_helper';
import {
    Dimmer
} from '../spinner';
import Pager from '../pager';
import Message from '../message';
import i18next from 'i18next';

import EmptyPreviewItem from '../../templates/empty_preview_item.hbs';
import DocumentItem from '../../templates/document_item.hbs';

const docsPerPage = 10;

class DocumentsView extends SubView {
    protected searchFilter: any;
    protected docTypeFilter: any;
    protected currentOffset: number;
    protected lastRequestParameters: { offset: number, docTypeFilter?: any, searchFilter?: any };

    constructor(container: any) {
        super(container);
    }

    init() {
        this.searchFilter = null;
        this.docTypeFilter = null;
        this.lastRequestParameters = {
            offset: -1
        };

        this.$parent.pager = new Pager(this.$parent.find('.menu.pagination'));
        this.$parent.pager.setPage(0);
        this._bindEvents();
        this.$parent.find('.file-listing').css('max-height', 1000);

        this._initDropdown();
    }

    _initDropdown() {
        // Init dropdown
        let self = this;
        let $docsDropdown = this.$parent.find('.doc-types.dropdown');
        $docsDropdown.dropdown({
            placeholder: i18next.t('portal.products.doc-type'),
            values: [],
            onChange: function(value, text, $selectedItem) {
                let newTypeFilter = (value == 'none' || value == '') ? null : value;
                if (newTypeFilter != null) {
                    newTypeFilter = newTypeFilter.replace(/rue/g, 'rü');
                } else {
                    $docsDropdown.dropdown('clear', true);
                }
                if (newTypeFilter != self.docTypeFilter) {
                    self.docTypeFilter = newTypeFilter;
                    self._loadDocuments(0);
                }
                return;
            }
        });
        Service.getDocTypes()
            .then(function(types) {
                // prepare values
                let typeList = $.map(types, function(type) {
                    if (type == null) {
                        return {
                            name: i18next.t(`portal.products.type.unassigned`),
                            value: '--'
                        };
                    }
                    type = type.replace(/rü/g, 'rue');
                    return {
                        name: i18next.t(`portal.products.type.${type}`),
                        value: type
                    };
                });
                // sort entries alphabetical
                typeList.sort(function(a, b) {
                    let aVal = a.name.toLowerCase();
                    let bVal = b.name.toLowerCase();
                    return aVal < bVal ? -1 : 1;
                });
                // add 'unselect' option
                typeList.unshift({
                    name: i18next.t('portal.products.unselect-type'),
                    value: 'none'
                });
                $docsDropdown.dropdown("change values", typeList);
            });
    }

    _bindEvents() {
        this.$parent.on('click', '.download.button', $.proxy(this._onDocumentDownload, this));
        this.$parent.find('.button.search').on('click', $.proxy(this._onFilterDocuments, this));
        this.$parent.find('.search input').on('keydown', (evt: KeyboardEvent) => {
            if (evt.keyCode != 13) {
                return;
            }

            this._onFilterDocuments(evt);
            evt.preventDefault();
        });

        this.$parent.pager.element.on('change', (evt, page, total) => {
            this._loadDocuments(page * docsPerPage);
        });
    }

    _unbindEvents() {
        this.$parent.off('click');
        this.$parent.find('.button.search').off('click');
        this.$parent.find('.search input').off('keydown');
    }

    _onShown() {
        this._loadDocuments(0);
    }

    _loadDocuments(offset) {
        // just for the case, offset has not been set
        offset = offset || 0;

        if (this.lastRequestParameters.offset == offset &&
            this.lastRequestParameters.searchFilter == this.searchFilter &&
            this.lastRequestParameters.docTypeFilter == this.docTypeFilter) {
            // no need for update
            return;
        }

        this.lastRequestParameters.offset = offset;
        this.lastRequestParameters.searchFilter = this.searchFilter;
        this.lastRequestParameters.docTypeFilter = this.docTypeFilter;

        const self = this;
        const $docs = this.$parent;
        const docsDimmer = new Dimmer($docs).show(i18next.t('portal.loading'));
        Service.getDocuments(offset, docsPerPage, this.searchFilter, this.docTypeFilter)
            .fail(function(msg) {
                docsDimmer.hide();
                Message.show(i18next.t('portal.error'), msg.statusText + ':<br>' + msg.responseText);
            })
            .then(function(data, textStatus, response) {
                self.currentOffset = offset;
                const totalCount = response.getResponseHeader('X-Documents-Count');
                const fromToRange = response.getResponseHeader('X-Documents-Range');
                $docs.pager.setPageCount(Math.ceil(totalCount * 1 / docsPerPage));

                const $list = $docs.find('.documents-list').empty();
                if (!data || !data.length) {
                    $list.append(EmptyPreviewItem());
                } else {
                    for (let i = 0; i < data.length; i++) {
                        $list.append(DocumentItem(data[i]));
                    }
                }
                $list.localize();
                docsDimmer.hide();
            });
    }

    _onDocumentDownload(evt) {
        const uid = $(evt.currentTarget).closest('.item').data('uid');
        DownloadHelper.downloadDocument(uid);
    }

    _onFilterDocuments(evt) {
        Service.logStatisticsEvent('documents', 'search');

        this.searchFilter = $(evt.currentTarget).parent().find('input').val().toLowerCase();
        this._loadDocuments(0);
    }

    dispose() {
        this._unbindEvents();
    }
}

export default DocumentsView;
